import React, {useState, useEffect} from "../../../../../_snowpack/pkg/react.js";
import "./row-edit.css";
import axios from "../../../../../_snowpack/pkg/axios.js";
const StoreSection = ({label = "", value = "", product, inventory, setInventory, setOpenToast, setToastMsg, fetching}) => {
  const [storeSection, setStoreSection] = useState(value);
  useEffect(() => {
    if (product.storeSection !== void 0 && product.StoreSection !== null) {
      setStoreSection(product.storeSection);
    }
  }, [fetching]);
  const onChange = (e) => {
    setStoreSection(e.target.value);
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (product.storeSection != storeSection) {
      handleChangeStoreSection(product.sellerSku, storeSection);
    }
  };
  const handleChangeStoreSection = async (sellerSku, storeSection2) => {
    const newInventory = inventory.products.filter((p) => p._id !== product._id);
    const {data} = await axios.post("api/v1/inventory/set-store-section", null, {params: {sellerSku, storeSection: storeSection2}});
    setInventory({products: [...newInventory, {...product, ...data}]});
    setToastMsg("You have successfully set store section.");
    setOpenToast(true);
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "row-edit-container"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, label), /* @__PURE__ */ React.createElement("input", {
    className: "value input left",
    value: storeSection,
    onChange,
    onClick: (e) => e.target.select(),
    onBlur
  }));
};
export default StoreSection;
