import React from "../../../_snowpack/pkg/react.js";
const Dashboard = () => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "coming-soon"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "coming-soon-text"
  }, /* @__PURE__ */ React.createElement("h1", null, "Content coming soon"), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, "Dashboard Page")), /* @__PURE__ */ React.createElement("p", null, "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor lectus duis suspendisse massa, tempor dictum ut sed. Nunc maecenas quisque integer tristique iaculis.")), /* @__PURE__ */ React.createElement("div", {
    className: "coming-soon-image"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/assets/images/coming-soon.svg",
    alt: "Coming Soon Image"
  })));
};
export default Dashboard;
