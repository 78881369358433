import React, {useEffect} from "../../../_snowpack/pkg/react.js";
import {Switch, Route, Redirect, useHistory, useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import ReplenLogoSVG from "../../components/replen-logo-svg/index.js";
import Login from "../../components/login/index.js";
import UpdateEmail from "../../components/update-email/index.js";
import ForgotPassword from "../../components/forgot-password/index.js";
import UpdatePassword from "../../components/update-password/index.js";
import "./logged-out.css";
const LoggedOutContainer = ({user, handleLoginSuccess}) => {
  const history = useHistory();
  const {pathname} = useLocation();
  const location = useLocation();
  useEffect(() => {
    if (pathname !== "/auth/update-email") {
      if (user)
        history.push("/shopping-list");
    }
  }, [user]);
  useEffect(() => {
    if (pathname !== "/auth/update-email") {
      if (user) {
        history.push("/");
      } else {
        const authRoutes = ["/auth/login", "/auth/forgot-password", "/auth/update-password"];
        if (!authRoutes.includes(location.pathname)) {
          history.push("/auth/login");
        }
      }
    }
  }, []);
  return pathname === "/auth/update-email" ? /* @__PURE__ */ React.createElement("div", {
    className: "logged-out"
  }, /* @__PURE__ */ React.createElement(ReplenLogoSVG, null), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/auth/update-email",
    render: (p) => /* @__PURE__ */ React.createElement(UpdateEmail, {
      ...p
    })
  }))) : user ? /* @__PURE__ */ React.createElement(Redirect, {
    to: "/dashboard"
  }) : /* @__PURE__ */ React.createElement("div", {
    className: "logged-out"
  }, /* @__PURE__ */ React.createElement(ReplenLogoSVG, null), /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/auth/login",
    render: (p) => /* @__PURE__ */ React.createElement(Login, {
      ...p,
      handleLoginSuccess
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/auth/forgot-password",
    render: (p) => /* @__PURE__ */ React.createElement(ForgotPassword, {
      ...p
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/auth/update-password",
    render: (p) => /* @__PURE__ */ React.createElement(UpdatePassword, {
      ...p
    })
  })));
};
export default LoggedOutContainer;
