import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import axios from "../../../_snowpack/pkg/axios.js";
import "./update-email.css";
const UpdateEmail = () => {
  const [updateResponse, setUpdateResponse] = useState({title: "Updating email address.", message: "Updating email address is ongoing. Please wait a second."});
  const updateEmailToken = new URLSearchParams(window.location.search).get("updateEmailToken");
  useEffect(() => {
    axios.get(`/api/v1/update-email/${updateEmailToken}`).then(({data}) => setUpdateResponse(data));
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "update-email"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "title"
  }, updateResponse.title), /* @__PURE__ */ React.createElement("p", {
    className: "message"
  }, updateResponse.message));
};
export default UpdateEmail;
