import React, {useState, useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import ReactDOM from "../../../_snowpack/pkg/react-dom.js";
import {Close} from "../../../_snowpack/pkg/@material-ui/icons.js";
import {taxCodes, sections} from "./tax-code-table.js";
import "./tax-code-input.css";
const TaxCodeInput = ({selectedTaxCode, setTaxCode}) => {
  const [search, setSearch] = useState(selectedTaxCode);
  const [isOpen, setIsOpen] = useState(false);
  const searchBarRef = useRef(null);
  const selectedTaxCodeRef = useRef(null);
  const resultsContainerRef = useRef(null);
  const normalizedSearch = search.toLowerCase().trim();
  const taxCodeFilter = (taxCode) => {
    const taxCodeIncludesSearch = taxCode.toLowerCase().includes(normalizedSearch);
    const descriptionIncludesSearch = taxCodes[taxCode].toLowerCase().includes(normalizedSearch);
    return taxCodeIncludesSearch || descriptionIncludesSearch;
  };
  const handleTaxCodeClick = (newTaxCode) => () => {
    setIsOpen(false);
    setTaxCode(newTaxCode);
  };
  useEffect(() => {
    if (isOpen) {
      setSearch("");
      searchBarRef.current.focus();
      selectedTaxCodeRef.current.scrollIntoView();
    }
  }, [isOpen]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("input", {
    value: selectedTaxCode,
    onClick: () => setIsOpen(true),
    style: {flexGrow: 1},
    readOnly: true
  }), ReactDOM.createPortal(/* @__PURE__ */ React.createElement("div", {
    className: `tax-code-search${isOpen ? " active" : ""}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "search-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "search-title"
  }, "Search Tax Code"), /* @__PURE__ */ React.createElement("button", {
    className: "search-close",
    onClick: () => setIsOpen(false)
  }, /* @__PURE__ */ React.createElement(Close, null), " Close"), /* @__PURE__ */ React.createElement("input", {
    type: "search",
    className: "search-bar",
    placeholder: "Input the tax code (or its description / section)",
    ref: searchBarRef,
    value: search,
    onInput: (e) => setSearch(e.target.value)
  })), /* @__PURE__ */ React.createElement("div", {
    className: "results-container",
    ref: resultsContainerRef
  }, Object.entries(sections).map(([section, sectionTaxCodes]) => {
    const titleIncludesSearch = section.toLowerCase().includes(normalizedSearch);
    const filteredTaxCodes = sectionTaxCodes.filter(taxCodeFilter);
    return titleIncludesSearch || filteredTaxCodes.length ? /* @__PURE__ */ React.createElement("div", {
      className: "section",
      key: section
    }, /* @__PURE__ */ React.createElement("div", {
      className: "title"
    }, section), (filteredTaxCodes.length ? filteredTaxCodes : sectionTaxCodes).map((taxCode) => {
      const isSelected = taxCode === selectedTaxCode;
      const taxCodeClass = `tax-code${isSelected ? " active" : ""}`;
      return /* @__PURE__ */ React.createElement("div", {
        className: taxCodeClass,
        onClick: handleTaxCodeClick(taxCode),
        key: taxCode,
        ref: isSelected ? selectedTaxCodeRef : null
      }, /* @__PURE__ */ React.createElement("span", null, taxCode), /* @__PURE__ */ React.createElement("span", null, taxCodes[taxCode]));
    })) : null;
  }))), document.body));
};
export default TaxCodeInput;
