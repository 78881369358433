import React, {useEffect, useState, useLayoutEffect} from "../../../../_snowpack/pkg/react.js";
import {useParams} from "../../../../_snowpack/pkg/react-router-dom.js";
import {CameraAlt} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import {Tooltip} from "../../../../_snowpack/pkg/@material-ui/core.js";
import axios from "../../../../_snowpack/pkg/axios.js";
import "./inventory-item.css";
import Toast from "../../toast/index.js";
import Quantities from "./accordions/quantities.js";
import ThirtyDays from "./accordions/thirtyDays.js";
import SixtyDays from "./accordions/sixtyDays.js";
import NinetyDays from "./accordions/ninetyDays.js";
import Cost from "./accordions/cost.js";
import BuyBox from "./accordions/buyBox.js";
import Row from "./row/index.js";
import StoreSection from "./row-edit/store-section.js";
import Supplier from "./row-edit/supplier.js";
import {useQuery} from "../../../../_snowpack/pkg/react-query.js";
import {chooseDefaultCalculationPrice, GetProfitEstimations} from "../../../utilities/functions.js";
import BuyLink from "./accordions/buyLink.js";
const InventoryItem = ({inventory, setInventory}) => {
  const {productId} = useParams();
  const [product, setProduct] = useState(inventory.products.filter((inv) => inv._id === productId)[0] ? inventory.products.filter((inv) => inv._id === productId)[0] : {});
  const [openToast, setOpenToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [expanded, setExpanded] = useState(false);
  const fetchProductDetails = async () => {
    const {data: data2} = await axios.get(`/api/v1/inventory/product-detail/${productId}`);
    return data2;
  };
  const {data, isFetching} = useQuery(productId, fetchProductDetails, {refetchOnWindowFocus: "always"});
  const [productCopy, setProductCopy] = useState({});
  const fulfilmentType = {
    Amazon: "Amazon FBA",
    Merchant: "Merchant FBM",
    Ebay: "Ebay"
  };
  const thumbnail = product?.imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${product.imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  useEffect(() => {
    if (data) {
      const {profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice, costPrice, fee, fulfilmentType: fulfilmentType2, shipBy, shippingRate, prepCenterFees, weight, enrolledInSnL, fbmFee} = data;
      const defaultPrice = chooseDefaultCalculationPrice({profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice});
      const updated_data = GetProfitEstimations({costPrice, defaultPrice, fee, fulfilmentType: fulfilmentType2, shipBy, shippingRate, prepCenterFees, weight, enrolledInSnL, fbmFee});
      setProduct({...data, ...updated_data, defaultPrice});
    }
  }, [data]);
  useEffect(() => {
    document.querySelector(".content").style.backgroundColor = "white";
    return () => {
      document.querySelector(".content").style.backgroundColor = "var(--replen-light-bg)";
    };
  }, [document.querySelector(".content")]);
  useEffect(() => {
    setProductCopy(product);
  }, [product]);
  const copyValue = (value, copiedField) => {
    navigator.clipboard.writeText(value);
    setToastMsg(`Succesfully copied ${copiedField} to clipboard`);
    setOpenToast(true);
  };
  const dateAdded = new Date(product?.openDate)?.toLocaleString("default", {month: "long", day: "numeric", year: "numeric"});
  const handleCloseModal = () => setOpenToast(false);
  return product && data ? /* @__PURE__ */ React.createElement("div", {
    className: "inventory-item"
  }, openToast && /* @__PURE__ */ React.createElement(Toast, {
    isOpen: openToast,
    handleClose: handleCloseModal,
    duration: 1500
  }, " ", toastMsg, " "), /* @__PURE__ */ React.createElement("div", {
    className: "item-preview"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "thumbnail",
    src: thumbnail,
    alt: product.title
  }), /* @__PURE__ */ React.createElement("div", {
    className: "title"
  }, product.title)), /* @__PURE__ */ React.createElement("div", {
    className: "item-details-container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "item-details"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "asin item-detail"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "ASIN"), /* @__PURE__ */ React.createElement("p", {
    className: "value",
    onClick: () => copyValue(product.asin, "ASIN")
  }, product.asin)), /* @__PURE__ */ React.createElement("div", {
    className: "sku item-detail"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "SKU"), /* @__PURE__ */ React.createElement("p", {
    className: "value",
    onClick: () => copyValue(product.sellerSku, "SKU")
  }, product.sellerSku)), /* @__PURE__ */ React.createElement("div", {
    className: "marketplace item-detail"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, fulfilmentType[product.fulfilmentType]))), /* @__PURE__ */ React.createElement("div", {
    className: "icon-links"
  }, /* @__PURE__ */ React.createElement("a", {
    href: "https://lens.google.com/uploadbyurl?url=https://images-na.ssl-images-amazon.com/images/I/" + product.imageUrl,
    target: "_blank"
  }, /* @__PURE__ */ React.createElement(Tooltip, {
    title: "Search by Google Lens"
  }, /* @__PURE__ */ React.createElement(CameraAlt, {
    className: "camera-search"
  }))), /* @__PURE__ */ React.createElement("a", {
    href: `https://keepa.com/#!product/1-${product.asin}`,
    target: "_blank",
    rel: "noopener noreferrer"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/assets/images/keepa-icon.svg"
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "accordion-grid",
    style: {marginTop: "2rem"}
  }, /* @__PURE__ */ React.createElement(Quantities, {
    expanded,
    setExpanded,
    product,
    fetching: isFetching,
    productCopy,
    setOpenToast,
    setToastMsg,
    setInventory,
    inventory
  })), /* @__PURE__ */ React.createElement("div", {
    className: "accordion-flex"
  }, /* @__PURE__ */ React.createElement(ThirtyDays, {
    expanded,
    setExpanded,
    product
  })), /* @__PURE__ */ React.createElement("div", {
    className: "accordion-flex"
  }, /* @__PURE__ */ React.createElement(SixtyDays, {
    expanded,
    setExpanded,
    product
  })), /* @__PURE__ */ React.createElement("div", {
    className: "accordion-flex"
  }, /* @__PURE__ */ React.createElement(NinetyDays, {
    expanded,
    setExpanded,
    product
  })), /* @__PURE__ */ React.createElement("div", {
    className: "accordion-grid"
  }, /* @__PURE__ */ React.createElement(Cost, {
    expanded,
    setExpanded,
    product,
    setProduct,
    fetching: isFetching,
    productCopy,
    setOpenToast,
    setToastMsg,
    setInventory,
    inventory
  })), /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement(Row, {
    label: "your price",
    value: product.listPrice ? `$${product?.listPrice}` : "N/A"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "accordion-flex"
  }, /* @__PURE__ */ React.createElement(BuyBox, {
    expanded,
    setExpanded,
    product
  })), /* @__PURE__ */ React.createElement("div", {
    className: "accordion-flex"
  }, /* @__PURE__ */ React.createElement(BuyLink, {
    expanded,
    setExpanded,
    product
  })), /* @__PURE__ */ React.createElement("div", {
    className: "row-edit"
  }, /* @__PURE__ */ React.createElement(Supplier, {
    label: "supplier",
    value: data?.supplier ? data.supplier : "N/A",
    product: data,
    setOpenToast,
    setToastMsg,
    inventory,
    setInventory,
    fetching: isFetching
  })), /* @__PURE__ */ React.createElement("div", {
    className: "row-edit"
  }, /* @__PURE__ */ React.createElement(StoreSection, {
    label: "store section",
    value: data?.storeSection ? data.storeSection : "N/A",
    product: data,
    setOpenToast,
    setToastMsg,
    inventory,
    setInventory,
    fetching: isFetching
  })), /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement(Row, {
    label: "status",
    value: product?.status ? product.status : "N/A"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement(Row, {
    label: "date added",
    value: dateAdded ? dateAdded : "N/A"
  })), /* @__PURE__ */ React.createElement("div", {
    style: {paddingBottom: "2rem"}
  })) : null;
};
export default InventoryItem;
