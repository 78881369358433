import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {Redirect, useHistory, useLocation} from "../../../_snowpack/pkg/react-router-dom.js";
import Header from "../../components/header/index.js";
import Content from "../../components/content/index.js";
import SideNav from "../../components/side-nav/index.js";
import BottomNav from "../../components/bottom-nav/index.js";
import axios from "../../../_snowpack/pkg/axios.js";
import "./logged-in.css";
import {useQuery, useQueryClient} from "../../../_snowpack/pkg/react-query.js";
import {chooseDefaultCalculationPrice, GetProfitEstimations} from "../../utilities/functions.js";
const LoggedInContainer = ({user, handleLogout, handleLoginSuccess}) => {
  const history = useHistory();
  const {pathname} = useLocation();
  const dateNow = new Date();
  useEffect(() => {
    if (user && pathname === "/")
      history.push("/shopping-list");
  }, [user]);
  useEffect(() => {
    localStorage.setItem("inventorySearch", "");
    localStorage.setItem("selectedInventory", "");
  }, []);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const handleUserMenuToggle = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };
  const handleNavToggle = () => {
    if (isNavOpen)
      setIsUserMenuOpen(false);
    setIsNavOpen(!isNavOpen);
  };
  const [isLongPress, setIsLongPress] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(null);
  const [deleteFunction, setDeleteFunction] = useState(null);
  const [skip, setSkip] = useState(0);
  const [inventory, setInventory] = useState({products: []});
  const fetchInventory = async () => {
    return await axios.get(`/api/v1/inventory?limit=500&skip=${skip}`);
  };
  const queryClient = useQueryClient();
  const {data} = useQuery(["inventoryItems", skip], fetchInventory, {
    retry: 3,
    refetchOnWindowFocus: false,
    enabled: skip != void 0,
    onSuccess: ({data: data3}) => {
      const {nextIndex, products} = data3;
      const new_products = products.map((p) => {
        const {profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice, costPrice, fee, fulfilmentType, shipBy, shippingRate, prepCenterFees, weight, enrolledInSnL, fbmFee} = p;
        const defaultPrice = chooseDefaultCalculationPrice({profitCalculatedBy, listPrice, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice});
        const updated_data = GetProfitEstimations({costPrice, defaultPrice, fee, fulfilmentType, shipBy, shippingRate, prepCenterFees, weight, enrolledInSnL, fbmFee});
        return {...p, ...updated_data, defaultPrice};
      });
      setSkip(nextIndex);
      setInventory((old) => {
        return {products: [...old.products, ...new_products]};
      });
      if (nextIndex === void 0) {
        queryClient.cancelQueries(["inventoryItems", skip]);
      }
    }
  });
  const [isRefetchCheckUserEnabled, setIsRefetchCheckUserEnabled] = useState(false);
  const [newCartItems, setNewCartItems] = useState(localStorage.getItem("newCartItems") || 0);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState({});
  const checkUser = async () => {
    return await axios.get("/api/v1/findUser");
  };
  const sendLoginRequest = async ({email, password}) => {
    try {
      const response = await axios.post("/api/v1/login", {email, password});
      const {token, user: user2} = await response.data;
      handleLoginSuccess(token, user2);
    } catch (error) {
      let errorMessage;
      switch (error?.response?.status) {
        case 400:
          errorMessage = "Invalid credentials, please check your email or password";
          break;
        case 500:
          errorMessage = "Server error, something went wrong on our end";
          break;
        default:
          errorMessage = "Network error, please check your internet connection";
      }
    }
  };
  useEffect(() => {
    if (isRefetchCheckUserEnabled) {
      setTimeout(() => {
        setIsRefetchCheckUserEnabled(false);
      }, 36e4);
    }
  }, [isRefetchCheckUserEnabled]);
  const {data2} = useQuery(["checkUser"], checkUser, {
    enabled: isRefetchCheckUserEnabled,
    initialData: {user: []},
    refetchInterval: 1e3,
    refetchOnReconnect: false,
    retry: false,
    onError: () => {
      const email = localStorage.getItem("newEmail");
      const password = localStorage.getItem("password");
      setIsRefetchCheckUserEnabled(false);
      sendLoginRequest({email, password});
    }
  });
  return user ? /* @__PURE__ */ React.createElement("div", {
    className: "logged-in"
  }, /* @__PURE__ */ React.createElement(Header, {
    user,
    handleNavToggle,
    handleLogout,
    isLongPress,
    setIsLongPress,
    setSelectedDelete,
    setOpenModal
  }), /* @__PURE__ */ React.createElement(SideNav, {
    state: {user, isNavOpen, isUserMenuOpen, newCartItems},
    handlers: {
      handleUserMenuToggle,
      handleNavToggle,
      handleLogout
    }
  }), /* @__PURE__ */ React.createElement(Content, {
    isLongPress,
    setIsLongPress,
    setDeleteFunction,
    selectedDelete,
    setSelectedDelete,
    setIsRefetchCheckUserEnabled,
    handleLogout,
    setNewCartItems,
    item,
    setItem,
    openModal,
    setOpenModal,
    user,
    skip,
    setSkip,
    inventory,
    setInventory
  }), /* @__PURE__ */ React.createElement(BottomNav, {
    isLongPress,
    setIsLongPress,
    pathname,
    deleteFunction,
    selectedDelete,
    setSelectedDelete,
    newCartItems
  })) : /* @__PURE__ */ React.createElement(Redirect, {
    to: "/auth/login"
  });
};
export default LoggedInContainer;
