import React, {useEffect, useState, useRef, useCallback, useMemo} from "../../../_snowpack/pkg/react.js";
import "./buy-list-group.css";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Tabs,
  Tab,
  Box,
  ListItemSecondaryAction,
  IconButton,
  SwipeableDrawer,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "../../../_snowpack/pkg/@material-ui/core.js";
import {Add, PlaylistAddCheck, LibraryAddCheck, Storage} from "../../../_snowpack/pkg/@material-ui/icons.js";
import useMediaQuery from "../../../_snowpack/pkg/@material-ui/core/useMediaQuery.js";
import axios from "../../../_snowpack/pkg/axios.js";
import {createTheme, ThemeProvider} from "../../../_snowpack/pkg/@material-ui/core/styles.js";
import {red} from "../../../_snowpack/pkg/@material-ui/core/colors.js";
import Spinner from "../spinner/index.js";
import Toast from "../toast/index.js";
import {useInfiniteQuery, useQueryClient, useMutation} from "../../../_snowpack/pkg/react-query.js";
import BuyListItems from "../buy-list-items/index.js";
const a11Props = (index) => {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`
  };
};
const theme = createTheme({
  palette: {
    primary: {
      main: red[500]
    }
  }
});
const TabPanel = (props) => {
  const {children, value, index, ...other} = props;
  return /* @__PURE__ */ React.createElement("div", {
    role: "tabpanel",
    hidden: value !== index,
    id: `simple-tabpanel-${index}`,
    "aria-labelledby": `simple-tab-${index}`,
    ...other
  }, value === index && /* @__PURE__ */ React.createElement(Box, {
    p: 3
  }, /* @__PURE__ */ React.createElement("span", null, children)));
};
const BuyListGroup = ({user}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [selectedBuyList, setSelectedBuyList] = useState({});
  const [tabValue, setTabValue] = useState("buy-lists");
  const [total, setTotal] = useState(2);
  const [inputText, setInputText] = useState("");
  const [addInputText, setAddInputText] = useState("");
  const [rowsPerPage, _setRowsPerPage] = useState(20);
  const [isOpen, setIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const [archiveInputText, setArchiveInputText] = useState("");
  const [buyListError, setBuyListError] = useState(false);
  const [buyListErrorText, setBuyListErrorText] = useState();
  let filters = {field: "name", value: tabValue === "buy-lists" ? inputText : archiveInputText, isArchive: tabValue === "buy-lists" ? false : true, limit: rowsPerPage, _page: 1};
  const queryClient = useQueryClient();
  const minWidth600 = useMediaQuery("(min-width:600px)");
  const minWidth720 = useMediaQuery("(min-width:720px)");
  const maxWidth1024 = useMediaQuery("(max-width:1024px)");
  useEffect(() => {
    let trialTotal = data?.pages[0]?.data?.total === void 0 ? 1e4 : data?.pages[0]?.data?.total;
    setTotal(Math.ceil(trialTotal / rowsPerPage));
    return () => source.cancel();
  }, [inputText, archiveInputText, tabValue]);
  const getQueryKey = () => {
    return ["buyLists", `${inputText}-${archiveInputText}-${tabValue}`];
  };
  const queryKey = useMemo(() => getQueryKey(), [inputText, archiveInputText, tabValue]);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const fetchBuyLists = ({pageParam = 1}) => {
    return axios({
      method: "GET",
      url: "/api/v1/buy-list-group/buyLists",
      params: {filters: {...filters, _page: pageParam}},
      cancelToken: source.token
    }).catch((err) => {
      if (axios.isCancel(err))
        return;
    });
  };
  const {
    isLoading,
    isError,
    error,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(queryKey, fetchBuyLists, {
    getNextPageParam: (_lastPage, pages) => {
      if (pages.length < total) {
        return pages.length + 1;
      } else {
        return void 0;
      }
    }
  });
  const addBuyList = (newGroup) => {
    const filters2 = {isArchive: false, field: "name", value: newGroup};
    return axios.post(`/api/v1/buy-list-group`, {filters: filters2});
  };
  const useAddBuyList = () => {
    return useMutation(addBuyList, {
      onSuccess: (data2) => {
        setShowAdd(false);
        setAddInputText("");
        setToastMessage(`BuyList ${data2.data.buyList.name} successfully added.`);
        setToastType("success");
        setIsOpen(true);
        queryClient.invalidateQueries(queryKey);
      }
    });
  };
  const {mutate: addNewBuyList} = useAddBuyList();
  const archiveBuyList = (params) => {
    const {id, filters: filters2} = params;
    return axios.put(`/api/v1/buy-list-group/${id}`, {archiveBuyList: id, filters: filters2});
  };
  const useArchiveBuyList = () => {
    return useMutation(archiveBuyList, {
      onSuccess: (xdata) => {
        setTotal(Math.ceil(data?.pages[0].data.total / rowsPerPage));
        queryClient.invalidateQueries(queryKey);
        setToastMessage(`BuyList ${xdata.data.buyList.name} successfully archived.`);
        setToastType("success");
        setIsOpen(true);
      }
    });
  };
  const {mutate: archiveBuyListQuery} = useArchiveBuyList();
  const restoreBuyList = (params) => {
    const {item, filters: filters2} = params;
    const {_id} = item;
    return axios.put(`/api/v1/buy-list-group/${_id}`, {restoreBuyList: _id, filters: filters2});
  };
  const useBuyListRestore = () => {
    return useMutation(restoreBuyList, {
      onSuccess: (data2) => {
        queryClient.invalidateQueries(queryKey);
        setToastMessage(`BuyList ${data2.data.buyList.name} successfully restored.`);
        setToastType("success");
        setIsOpen(true);
      }
    });
  };
  const {mutate: restoreBuyListQuery} = useBuyListRestore();
  const handleRestore = (item) => {
    const params = {item, filters};
    restoreBuyListQuery(params);
  };
  const deleteBuyList = (params) => {
    const {item, filters: filters2} = params;
    const {_id} = item;
    return axios({
      url: `/api/v1/buy-list-group/${_id}`,
      method: "DELETE",
      params: {filters: filters2}
    });
  };
  const useDeleteBuyList = () => {
    return useMutation(deleteBuyList, {
      onSuccess: (data2) => {
        queryClient.invalidateQueries(queryKey);
        console.log("data total", data2.data.total);
        console.log("data", data2);
        console.log("total", total);
        setToastMessage(`Buy List successfully deleted.`);
        setToastType("success");
        setIsOpen(true);
      },
      onError: (error2) => {
        if (error2 == "Error: Request failed with status code 403") {
          setToastMessage(`Buy List has item(s) and cannot be deleted.`);
          setToastType("warning");
          setIsOpen(true);
        }
      }
    });
  };
  const {mutate: deleteBuyListQuery} = useDeleteBuyList();
  const handleDeleteBuyList = (item) => {
    const params = {item, filters};
    if (confirm(`Are you sure to delete ${item.name}?`)) {
      deleteBuyListQuery(params);
    }
  };
  const handleArchiveBuyList = (id) => {
    const params = {id, filters};
    archiveBuyListQuery(params);
  };
  const handleAddBuyList = (event) => {
    console.log("addInputText", addInputText);
    if (addInputText !== "") {
      addNewBuyList(addInputText);
      setBuyListErrorText();
      setBuyListError(false);
    } else {
      setBuyListError(true);
      setBuyListErrorText("Buy List name is required.");
    }
  };
  const handleCancel = () => {
    setShowAdd(false);
    setBuyListError(false);
    setBuyListErrorText();
  };
  const handleCreateBuyList = () => {
    setShowAdd(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const observer = useRef();
  const lastBuyListElementRef = useCallback((node) => {
    if (isLoading)
      return;
    if (observer.current)
      observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage) {
        let trialTotal = data?.pages[0]?.data?.total === void 0 ? 40 : data?.pages[0]?.data?.total;
        setTotal(Math.ceil(trialTotal / rowsPerPage));
        fetchNextPage();
      }
    });
    if (node)
      observer.current.observe(node);
  }, [isLoading, hasNextPage]);
  const [showBuyListItem, setShowBuyListItem] = useState(false);
  const toggleDrawer = (item, open) => (e) => {
    setSelectedBuyList(item);
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift"))
      return;
    setShowBuyListItem(open);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "buy-list-group-page"
  }, /* @__PURE__ */ React.createElement(SwipeableDrawer, {
    anchor: "right",
    variant: "temporary",
    open: showBuyListItem,
    onClose: toggleDrawer(false),
    onOpen: toggleDrawer(true),
    ModalProps: {keepMounted: true}
  }, /* @__PURE__ */ React.createElement(BuyListItems, {
    buylist: selectedBuyList,
    toggleDrawer,
    user
  })), /* @__PURE__ */ React.createElement(ThemeProvider, {
    theme
  }, /* @__PURE__ */ React.createElement(Tabs, {
    value: tabValue,
    onChange: handleTabChange,
    indicatorColor: "primary",
    textColor: "primary",
    centered: true
  }, /* @__PURE__ */ React.createElement(Tab, {
    icon: /* @__PURE__ */ React.createElement(PlaylistAddCheck, null),
    value: "buy-lists",
    label: "Buy Lists",
    ...a11Props("buy-lists"),
    wrapped: true
  }), /* @__PURE__ */ React.createElement(Tab, {
    icon: /* @__PURE__ */ React.createElement(Storage, null),
    value: "archive-buy-lists",
    label: "Archived Buy Lists",
    ...a11Props("archive-buy-lists"),
    wrapped: true
  }))), /* @__PURE__ */ React.createElement(TabPanel, {
    value: tabValue,
    index: "buy-lists"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "buy-lists"
  }, /* @__PURE__ */ React.createElement(Toast, {
    isOpen,
    handleClose: () => setIsOpen(false),
    type: toastType
  }, toastMessage), /* @__PURE__ */ React.createElement("div", {
    className: "controls"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "search",
    name: "search-buylist",
    variant: "",
    value: inputText,
    placeholder: "Search Buy Lists",
    onChange: (e) => setInputText(e.target.value)
  }), /* @__PURE__ */ React.createElement(IconButton, {
    className: "btn-add",
    onClick: handleCreateBuyList
  }, /* @__PURE__ */ React.createElement(Add, null), "Create"), /* @__PURE__ */ React.createElement(Dialog, {
    open: showAdd
  }, /* @__PURE__ */ React.createElement(DialogTitle, {
    className: "title-add",
    style: {textAlign: "center"}
  }, "Create Buy List"), /* @__PURE__ */ React.createElement(DialogContent, null, /* @__PURE__ */ React.createElement(TextField, {
    error: buyListError,
    helperText: buyListErrorText,
    className: "search",
    placeholder: "Enter Buy List Name",
    variant: "outlined",
    size: "small",
    value: addInputText,
    onChange: (e) => setAddInputText(e.target.value)
  })), /* @__PURE__ */ React.createElement(DialogActions, {
    className: "action-section",
    style: {display: "flex", justifyContent: "center"}
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "action-btn-secondary",
    onClick: handleCancel
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button, {
    className: "action-btn-primary",
    onClick: handleAddBuyList
  }, "Create")))), /* @__PURE__ */ React.createElement("div", {
    className: "list-scroll"
  }, /* @__PURE__ */ React.createElement(List, {
    component: "nav"
  }, data && data?.pages && data?.pages.map((page) => page?.data?.buyLists?.map((item, i) => {
    if (page.data.buyLists.length === i + 1) {
      return /* @__PURE__ */ React.createElement(ListItem, {
        ref: lastBuyListElementRef,
        key: i,
        dense: !(minWidth720 && maxWidth1024) || !minWidth600,
        button: true,
        divider: true,
        selected: item === selectedBuyList,
        onClick: () => setSelectedBuyList(item)
      }, /* @__PURE__ */ React.createElement(ListItemText, {
        className: "list-text",
        primary: item.name,
        onClick: toggleDrawer(item, true)
      }), /* @__PURE__ */ React.createElement(ListItemSecondaryAction, {
        className: "btn-wrapper"
      }, /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "delete"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: (e) => handleArchiveBuyList(item._id)
      }, "Archive")), /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "delete"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: () => handleDeleteBuyList(item)
      }, "Delete"))));
    } else {
      return /* @__PURE__ */ React.createElement(ListItem, {
        key: i,
        dense: !(minWidth720 && maxWidth1024) || !minWidth600,
        button: true,
        divider: true,
        selected: item === selectedBuyList,
        onClick: () => setSelectedBuyList(item)
      }, /* @__PURE__ */ React.createElement(ListItemText, {
        className: "list-text",
        primary: item.name,
        onClick: toggleDrawer(item, true)
      }), /* @__PURE__ */ React.createElement(ListItemSecondaryAction, {
        className: "btn-wrapper"
      }, /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "delete"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: (e) => handleArchiveBuyList(item._id)
      }, "Archive")), /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "delete"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: () => handleDeleteBuyList(item)
      }, "Delete"))));
    }
  })))), isLoading && /* @__PURE__ */ React.createElement("div", {
    className: "buy-lists"
  }, /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true
  })), isFetchingNextPage && /* @__PURE__ */ React.createElement("div", {
    className: "buy-lists"
  }, /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true
  })), isError && /* @__PURE__ */ React.createElement("h2", null, error.message))), /* @__PURE__ */ React.createElement(TabPanel, {
    value: tabValue,
    index: "archive-buy-lists"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "buy-lists"
  }, /* @__PURE__ */ React.createElement(Toast, {
    isOpen,
    handleClose: () => setIsOpen(false),
    type: toastType
  }, toastMessage), /* @__PURE__ */ React.createElement("div", {
    className: "controls"
  }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("input", {
    className: "search-archive",
    name: "search-archive-buylist",
    value: archiveInputText,
    placeholder: "Search Archived Buy Lists",
    onChange: (e) => setArchiveInputText(e.target.value)
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "list-scroll"
  }, /* @__PURE__ */ React.createElement(List, {
    component: "nav"
  }, data && data?.pages && data?.pages?.map((page) => page?.data?.buyLists?.map((item, i) => {
    if (page.data.buyLists.length === i + 1) {
      return /* @__PURE__ */ React.createElement(ListItem, {
        ref: lastBuyListElementRef,
        key: i,
        dense: !(minWidth720 && maxWidth1024) || !minWidth600,
        selected: selectedBuyList === item,
        button: true,
        divider: true,
        onClick: () => setSelectedBuyList(item)
      }, /* @__PURE__ */ React.createElement(ListItemText, {
        className: "list-text",
        primary: item.name,
        onClick: toggleDrawer(item, true)
      }), /* @__PURE__ */ React.createElement(ListItemSecondaryAction, {
        className: "btn-wrapper"
      }, /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "restore"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: () => handleRestore(item)
      }, "Restore")), /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "delete"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: () => handleDeleteBuyList(item)
      }, "Delete"))));
    } else {
      return /* @__PURE__ */ React.createElement(ListItem, {
        key: i,
        dense: !(minWidth720 && maxWidth1024) || !minWidth600,
        button: true,
        divider: true,
        selected: selectedBuyList === item,
        onClick: () => setSelectedBuyList(item)
      }, /* @__PURE__ */ React.createElement(ListItemText, {
        className: "list-text",
        primary: item.name,
        onClick: toggleDrawer(item, true)
      }), /* @__PURE__ */ React.createElement(ListItemSecondaryAction, {
        className: "btn-wrapper"
      }, /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "restore"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: () => handleRestore(item)
      }, "Restore")), /* @__PURE__ */ React.createElement(IconButton, {
        edge: "end",
        "aria-label": "delete"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "btn-action",
        onClick: () => handleDeleteBuyList(item)
      }, "Delete"))));
    }
  })))), isLoading && /* @__PURE__ */ React.createElement("div", {
    className: "buy-lists"
  }, /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true
  })), isFetchingNextPage && /* @__PURE__ */ React.createElement("div", {
    className: "buy-lists"
  }, /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true
  })), isError && /* @__PURE__ */ React.createElement("h2", null, error.message)))));
};
export default BuyListGroup;
