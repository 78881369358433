import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  InputAdornment,
  TextField,
  IconButton
} from "../../../_snowpack/pkg/@material-ui/core.js";
import {List as ListIcon, Search, Add} from "../../../_snowpack/pkg/@material-ui/icons.js";
import {green} from "../../../_snowpack/pkg/@material-ui/core/colors.js";
import "./buy-list-selection.css";
import axios from "../../../_snowpack/pkg/axios.js";
const BuyListGroupSelection = ({onBuyList, showBuyList, setShowBuyList, selectedItem, setSelectedItem, user}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [buyLists, setBuyLists] = useState([]);
  const [errors, setErrors] = useState("");
  useEffect(() => {
    getBuyLists();
  }, [showBuyList]);
  const getBuyLists = async () => {
    const response = await axios.get("/api/v1/buy-list-group");
    if (response.status === 200) {
      setBuyLists(response.data);
    } else {
      setErrors("An error has occured while getting Buy lLists");
    }
  };
  const handleSearchBuyList = async (filter) => {
    const params = new URLSearchParams();
    params.append("filter", filter);
    const response = await axios.get(`/api/v1/buy-list-group?${params.toString()}`);
    if (response.status === 200) {
      setBuyLists(response.data);
    } else {
      setErrors("An error has occured while getting Buy Lists");
    }
  };
  const handleAddBuyList = async (buyList) => {
    const filters = {isArchive: false, field: "name", value: buyList};
    const response = await axios.post("/api/v1/buy-list-group", {filters});
    if (response.status === 200) {
      setBuyLists([...buyLists, response.data.buyList]);
      setAddText("");
    } else {
      setErrors("An error has occured while getting Buy Lists");
    }
  };
  const handleKeyDown = (event) => {
    const {name, value} = event.target;
    if (event.keyCode == 13) {
      if (name === "search-text") {
        handleSearchBuyList(value);
      }
      if (name === "add-text") {
        handleAddBuyList(value);
      }
    }
  };
  const handleAdd = () => {
    setShowAdd(true);
  };
  const handleCancelAdd = () => {
    setShowAdd(false);
  };
  const handleCancel = () => {
    const defBuylist = localStorage.getItem(`default-buylist-${user?._id}`);
    if (defBuylist) {
      const jsonBuyList = JSON.parse(defBuylist);
      setSelectedItem(jsonBuyList);
    }
    setShowBuyList(false);
    setErrors("");
  };
  const handleItemSelected = (item) => {
    setSelectedItem(item);
    if (item) {
      setErrors("");
    }
  };
  const handleAddToLocalInv = () => {
    if (showBuyList && selectedItem) {
      onBuyList();
      localStorage.setItem(`default-buylist-${user?._id}`, JSON.stringify(selectedItem));
      setShowBuyList(false);
    } else if (showBuyList && !selectedItem) {
      setErrors("Please select a buy list!");
    }
  };
  const [searcText, setSearchText] = useState("");
  const [addText, setAddText] = useState("");
  const handleTextChanged = (event) => {
    const {name, value} = event.target;
    if (name === "search-text") {
      setSearchText(value);
    } else if (name === "add-text") {
      setAddText(value);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Dialog, {
    "aria-labelledby": "form-dialog-title",
    open: showBuyList,
    onClose: handleCancel
  }, /* @__PURE__ */ React.createElement(DialogTitle, {
    id: "form-dialog-title"
  }, "Select BuyList"), /* @__PURE__ */ React.createElement(DialogContent, null, showAdd ? /* @__PURE__ */ React.createElement(Box, {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement(TextField, {
    id: "input-with-icon-textfield",
    label: "Add Buy List",
    variant: "outlined",
    size: "small",
    name: "add-text",
    value: addText,
    onChange: handleTextChanged,
    onKeyDown: handleKeyDown,
    InputProps: {
      endAdornment: /* @__PURE__ */ React.createElement(InputAdornment, {
        position: "end"
      }, /* @__PURE__ */ React.createElement(IconButton, {
        onClick: () => handleAddBuyList(addText)
      }, /* @__PURE__ */ React.createElement(Add, {
        className: "btn-add"
      })))
    }
  }), /* @__PURE__ */ React.createElement(Divider, {
    className: "divider"
  }), /* @__PURE__ */ React.createElement(Button, {
    className: "input-btn",
    onClick: handleCancelAdd,
    size: "small",
    variant: "text"
  }, "Cancel")) : /* @__PURE__ */ React.createElement(Box, {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement(TextField, {
    id: "input-with-icon-textfield",
    label: "Search Buy List",
    variant: "outlined",
    size: "small",
    name: "search-text",
    value: searcText,
    onChange: handleTextChanged,
    onKeyDown: handleKeyDown,
    InputProps: {
      endAdornment: /* @__PURE__ */ React.createElement(InputAdornment, {
        position: "end"
      }, /* @__PURE__ */ React.createElement(IconButton, {
        onClick: () => handleSearchBuyList(searcText)
      }, /* @__PURE__ */ React.createElement(Search, null)))
    }
  }), /* @__PURE__ */ React.createElement(Divider, {
    className: "divider"
  }), /* @__PURE__ */ React.createElement(Button, {
    className: "input-btn",
    onClick: handleAdd,
    size: "small"
  }, "Create")), /* @__PURE__ */ React.createElement(Divider, {
    className: "divider"
  }), /* @__PURE__ */ React.createElement(List, {
    component: "nav",
    "aria-labelledby": "main-buylist",
    className: "buy-list"
  }, buyLists.sort((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1).map((list, i) => /* @__PURE__ */ React.createElement(ListItem, {
    key: i,
    dense: true,
    disableGutters: true,
    button: true,
    selected: selectedItem?._id === list?._id,
    onClick: () => handleItemSelected(list)
  }, /* @__PURE__ */ React.createElement(ListItemIcon, null, /* @__PURE__ */ React.createElement(ListIcon, null)), /* @__PURE__ */ React.createElement(ListItemText, {
    primary: list.name
  }))))), errors && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Divider, null), " ", /* @__PURE__ */ React.createElement(Box, {
    component: "span",
    className: "error"
  }, errors)), /* @__PURE__ */ React.createElement(Divider, {
    className: "divider"
  }), /* @__PURE__ */ React.createElement(DialogActions, {
    className: "action-section"
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "action-btn-secondary",
    size: "small",
    onClick: handleCancel
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button, {
    className: "action-btn-primary",
    size: "small",
    onClick: handleAddToLocalInv
  }, "Ok"))));
};
export default BuyListGroupSelection;
