import React from "../../../_snowpack/pkg/react.js";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "../../../_snowpack/pkg/@material-ui/core.js";
import {useRouteMatch, useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
export default function ConfirmResolve({open, setOpen, item, setRows, setIsLoading}) {
  const history = useHistory();
  const match = useRouteMatch("/unresolved-products/pack/:childId");
  const handleClose = () => {
    setOpen(false);
  };
  const handleResolve = () => {
    setIsLoading(true);
    handleClose();
    axios.post(`/api/v1/child-inventory/resolve/${item._id || item.childId}`).then((res) => {
      axios.get("/api/v1/child-inventory/unresolved").then(({data}) => {
        setRows(data.data);
        setIsLoading(false);
        if (match && match.path == "/unresolved-products/pack/:childId") {
          history.push("/unresolved-products");
        }
      });
    });
  };
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Dialog, {
    open,
    onClose: handleClose,
    "aria-labelledby": "alert-dialog-title",
    "aria-describedby": "alert-dialog-description"
  }, /* @__PURE__ */ React.createElement(DialogTitle, {
    id: "alert-dialog-title"
  }, "Confirm Resolve"), /* @__PURE__ */ React.createElement(DialogContent, null, /* @__PURE__ */ React.createElement(DialogContentText, {
    id: "alert-dialog-description"
  }, 'Resolve child product "', item.title, '"?')), /* @__PURE__ */ React.createElement(DialogActions, null, /* @__PURE__ */ React.createElement(Button, {
    onClick: handleClose,
    autoFocus: true,
    style: {color: "var(--replen-orange)"}
  }, "Disagree"), /* @__PURE__ */ React.createElement(Button, {
    onClick: handleResolve,
    style: {color: "var(--replen-orange)"}
  }, "Agree"))));
}
