import React from "../../../../../_snowpack/pkg/react.js";
import "./row.css";
const Row = ({label = "", value = ""}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "row-container"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, label), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, value));
};
export default Row;
