import { c as createCommonjsModule, g as getDefaultExportFromCjs } from './_commonjsHelpers-8c19dec8.js';

var defineProperty = createCommonjsModule(function (module) {
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

module.exports = _defineProperty;
module.exports["default"] = module.exports, module.exports.__esModule = true;
});

var _defineProperty = /*@__PURE__*/getDefaultExportFromCjs(defineProperty);

export { _defineProperty as _, defineProperty as d };
