import React, {useState, useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import {useQueryClient, useQuery, useMutation} from "../../../_snowpack/pkg/react-query.js";
import {Add, Remove, Close, ArrowDropDown} from "../../../_snowpack/pkg/@material-ui/icons.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {DateTime} from "../../../_snowpack/pkg/luxon.js";
import CurrencyInput from "../../../_snowpack/pkg/react-currency-input-field.js";
import axios from "../../../_snowpack/pkg/axios.js";
import TaxCodeInput from "../tax-code-input/index.js";
import numberToUSD from "../../utilities/js/number-to-usd.js";
import {useLongPress} from "../../../_snowpack/pkg/use-long-press.js";
import {useMediaQuery} from "../../../_snowpack/pkg/react-responsive.js";
import BuyListGroupSelection from "../buy-list-selection/buy-list-selection.js";
import DefaultBuylist from "../default-buylist/index.js";
import BuyListMergeSelection from "../buy-list-merge-selection/buy-list-merge-selection.js";
import Spinner from "../spinner/index.js";
import Toast from "../toast/index.js";
import "./bought-qty.css";
const BoughtQty = ({selected, setSelected, isRefetchingEnabled, setIsRefetchingEnabled, pageFrom, isLongPress, setIsLongPress, setDeleteFunction, selectedDelete, setSelectedDelete, list, setNewCartItems, user}) => {
  const {
    title,
    asin,
    sellerSku,
    costPrice,
    supplier,
    decidedBuyQuantity,
    isSellable,
    taxCode: defaultTaxCode,
    imageUrl,
    afnTotalQuantity,
    storeSection,
    isAlwaysBuyList,
    notesSection,
    customTitle,
    purchaseLink
  } = selected;
  const isMobile = useMediaQuery({query: "(max-width: 600px)"});
  const isTablet = useMediaQuery({query: "(min-width: 720px) and (max-width: 1024px) and (orientation: portrait)"});
  const [amazonUrl, setAmazonUrl] = useState(null);
  const [showBuyList, setShowBuyList] = useState(false);
  const [expandedParent, setExpandedParent] = useState([]);
  const [fetchingParents, setFetchingParents] = useState(false);
  const [parentItems, setParentItems] = useState([]);
  const [childItems, setChildItems] = useState([]);
  const [productChildId, setProductChildId] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [showBuyListMerge, setShowBuyListMerge] = useState(false);
  const [buyListArray, setBuyListArray] = useState([]);
  const [buyQty, setBuyQty] = useState(decidedBuyQuantity);
  useEffect(() => {
    checkUserMarketPlace();
  }, [amazonUrl]);
  useEffect(() => {
    if (isMobile && isTablet) {
      setIsLongPress(false);
      setSelectedDelete(null);
    }
  }, []);
  useEffect(() => {
    setParentItems([]);
    setFetchingParents(true);
    setExpandedParent([]);
    axios.get(`/api/v1/child-inventory/productInfo/${asin}?sellerSku=${sellerSku}`).then(({data: data2}) => {
      document.querySelector(".content").scrollTo(0, 0);
      const [childProduct] = data2.products;
      const childId = childProduct.childId;
      setProductChildId(childId);
      if (childId) {
        axios.get(`/api/v1/child-inventory/parent/${childId}`).then(({data: data3}) => {
          setParentItems(data3);
          setFetchingParents(false);
        }).catch((err) => console.error(err));
      }
    }).catch((err) => console.error(err));
  }, [selected]);
  useEffect(() => {
    parentItems.map((parentItem) => {
      if (parentItem.childItems.length > 0) {
        parentItem.childItems.map(({child}) => {
          axios.get(`/api/v1/child-inventory/child-info/${child}`).then(({data: data2}) => setChildItems((old) => [...old, data2])).catch((err) => console.log(err));
        });
      }
    });
  }, [parentItems]);
  const initialFormState = {
    qty: 0,
    expiryDate: "",
    taxCode: defaultTaxCode ?? "A_GEN_TAX",
    condition: "New",
    costPrice: costPrice ?? ""
  };
  const [form, updateForm] = useState({...initialFormState});
  const [isLoading, setIsLoading] = useState(false);
  const {qty, expiryDate, taxCode, condition, costPrice: formCost} = form;
  const {data} = useQuery(["inventoryMovements", sellerSku], getLocalInventoryMovements, {
    enabled: isRefetchingEnabled,
    initialData: {inventoryMovements: []},
    refetchInterval: 1e3,
    refetchOnReconnect: false
  });
  useEffect(() => {
    if (data.inventoryMovements.length === 0) {
      setIsLongPress(false);
    }
  }, [data]);
  const qtyInputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const thumbnail = imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  const history = useHistory();
  const queryClient = useQueryClient();
  const addToCartMutation = useMutation(addToCart, {
    retry: 3,
    onMutate: async (formData) => {
      const {quantity: inQuantity, pwaDateCreated, ...otherDetails} = formData;
      updateForm((prevState) => {
        return {...initialFormState, taxCode: prevState.taxCode, expiryDate: prevState.expiryDate};
      });
      setSelected((old) => ({...old, parentBuyQty: old.parentBuyQty - inQuantity}));
      setIsRefetchingEnabled(false);
      await queryClient.cancelQueries("shoppingList");
      await queryClient.cancelQueries(["inventoryMovements", sellerSku]);
      const newInventoryMovement = {inQuantity, pwaDateCreated, otherDetails};
      const previousValues = {
        shoppingList: queryClient.getQueryData("shoppingList"),
        inventoryMovements: queryClient.getQueryData(["inventoryMovements", sellerSku])
      };
      queryClient.setQueryData(["inventoryMovements", sellerSku], (old) => ({
        ...old,
        inventoryMovements: [newInventoryMovement, ...old.inventoryMovements]
      }));
      queryClient.setQueryData("shoppingList", (old) => {
        const productIndex = old.products.findIndex((product) => product.sellerSku === sellerSku);
        const decreasedQty = old.products[productIndex].decidedBuyQuantity - inQuantity;
        console.log("old", old);
        old.products[productIndex].decidedBuyQuantity = decreasedQty > 0 ? decreasedQty : 0;
        setBuyQty(decreasedQty > 0 ? decreasedQty : 0);
        return old;
      });
      return previousValues;
    },
    onError: (err, variables, {inventoryMovements, shoppingList}) => {
      console.error("Mutation error", err);
      console.error("Mutation variables", variables);
      queryClient.setQueryData("shoppingList", shoppingList);
      queryClient.setQueryData(["inventoryMovements", sellerSku], inventoryMovements);
      setToastType("error");
      setToastMessage("Something went wrong.");
      setIsToastOpen(true);
    },
    onSettled: () => {
      setIsRefetchingEnabled(true);
      queryClient.invalidateQueries("shoppingList");
      queryClient.invalidateQueries(["inventoryMovements", sellerSku]);
    },
    onSuccess: (data2) => {
      setIsLoading(false);
      setToastType("success");
      setToastMessage("Product was successfully moved to local inventory.");
      setIsToastOpen(true);
      setNewCartItems((old) => +old + 1);
      if (!isAlwaysBuyList && buyQty < 1) {
        history.goBack();
      }
    }
  });
  const removeFromLocalInventoryMutation = useMutation(removeFromLocalInventory, {
    retry: 3,
    onMutate: async ({index}) => {
      setIsRefetchingEnabled(false);
      await queryClient.cancelQueries("shoppingList");
      await queryClient.cancelQueries(["inventoryMovements", sellerSku]);
      const previousValues = {
        shoppingList: queryClient.getQueryData("shoppingList"),
        inventoryMovements: queryClient.getQueryData(["inventoryMovements", sellerSku])
      };
      queryClient.setQueryData(["inventoryMovements", sellerSku], (old) => ({
        ...old,
        inventoryMovements: [
          ...old.inventoryMovements.filter((_, i) => i !== index)
        ]
      }));
      queryClient.setQueryData("shoppingList", (old) => {
        const productIndex = old.products.findIndex((product) => product.sellerSku === sellerSku);
        const removedQty = data.inventoryMovements[index].inQuantity;
        old.products[productIndex].parentBuyQty += removedQty;
        setBuyQty(buyQty + removedQty);
        return old;
      });
      return previousValues;
    },
    onError: (err, variables, {inventoryMovements, shoppingList}) => {
      console.error("Mutation error", err);
      console.error("Mutation variables", variables);
      queryClient.setQueryData("shoppingList", shoppingList);
      queryClient.setQueryData(["inventoryMovements", sellerSku], inventoryMovements);
    },
    onSettled: () => {
      setIsRefetchingEnabled(true);
      queryClient.invalidateQueries(["inventoryMovements", sellerSku]);
      queryClient.invalidateQueries("shoppingList");
    },
    onSuccess: () => {
      if (pageFrom === "shopped-list") {
        history.replace(`/shopping-list/${sellerSku}`);
      }
      setIsLoading(false);
    }
  });
  async function getLocalInventoryMovements() {
    const response = await axios.get("/api/v1/inventory/inventory-movements", {params: {sellerSku, isSellable}});
    return response.data;
  }
  async function addToLocalInventory(formData) {
    const {qty: qty2, pwaDateCreated, ...otherDetails} = formData;
    return axios.post("/api/v1/inventory/addUpdate-pwa-local-inventory", {
      sellerSku,
      localInventory: qty2,
      isDeleted: false,
      otherDetails,
      pwaDateCreated,
      afnTotalQuantity,
      buyListId: selectedItem._id
    });
  }
  async function removeFromLocalInventory({index, pwaDateUpdated}) {
    const {pwaDateCreated, buyListId, ...otherDetails} = data.inventoryMovements[index];
    return axios.post("/api/v1/inventory/addUpdate-pwa-local-inventory", {
      sellerSku,
      isDeleted: true,
      otherDetails,
      pwaDateCreated,
      pwaDateUpdated,
      isSellable,
      buyListId: buyListId?._id
    });
  }
  async function addToCart(requestBody) {
    setIsLoading(true);
    return axios.post("api/v1/cart", requestBody);
  }
  const checkUserMarketPlace = () => {
    const {marketplaceId} = user.mws || {};
    if (marketplaceId) {
      switch (marketplaceId) {
        case "ATVPDKIKX0DER":
          setAmazonUrl("https://amazon.com");
          break;
        case "A1AM78C64UM0Y8":
          setAmazonUrl("https://amazon.com.mx");
          break;
        case "A2EUQ1WTGCTBG2":
          setAmazonUrl("https://amazon.ca");
          break;
        default:
          setAmazonUrl("https://amazon.com");
      }
    }
  };
  const [selectedItem, setSelectedItem] = useState(void 0);
  const handleShowBuyList = (event) => {
    event.preventDefault();
    addToCartMutation.mutate({...form, pwaDateCreated: Date.now()});
  };
  const handleAddToCart = () => {
    const {asin: asin2, sellerSku: sellerSku2, imageUrl: imageUrl2, title: title2, productId, _id} = selected;
    const {qty: quantity, costPrice: costPrice2, condition: condition2, expiryDate: expiryDate2, taxCode: taxCode2} = form;
    const requestBody = {asin: asin2, sellerSku: sellerSku2, imageUrl: imageUrl2, title: title2, productId: productId ? productId : _id, quantity, costPrice: costPrice2, condition: condition2, expiryDate: expiryDate2, taxCode: taxCode2, buyListId: selectedItem._id};
    addToCartMutation.mutate(requestBody);
  };
  const checkCartBuyList = (event) => {
    event.preventDefault();
    if (!selectedItem) {
      setShowBuyList(true);
    } else {
      const {_id} = selected;
      const childId = _id;
      axios.get(`/api/v1/child-inventory/child-info/${childId}`).then((res) => {
        const {buyListId} = res.data;
        if (buyListId) {
          if (buyListId._id !== selectedItem._id) {
            setBuyListArray([{...buyListId, status: "Prior"}, {...selectedItem, status: "New"}]);
            setShowBuyListMerge(true);
          } else {
            handleAddToCart();
          }
        } else {
          handleAddToCart();
        }
      }).catch((err) => console.log("BuyListErr", err));
    }
  };
  const handleRemoveFromLocalInventory = (index) => () => {
    setIsLoading(true);
    removeFromLocalInventoryMutation.mutate({index, pwaDateUpdated: Date.now()});
  };
  const handleDeleteRow = (index) => {
    setIsLoading(true);
    removeFromLocalInventoryMutation.mutate({index, pwaDateUpdated: Date.now()});
  };
  useEffect(() => {
    setDeleteFunction(() => handleDeleteRow);
  }, []);
  const onInputChange = (event) => {
    let {name, value} = event.target;
    if (name === "qty")
      value = Number(value);
    updateForm((prevState) => {
      return {...prevState, [name]: value};
    });
  };
  const incrementQty = () => {
    updateForm((prevState) => {
      return {...prevState, qty: Number(prevState.qty) + 1};
    });
  };
  const decrementQty = () => {
    const oneDecreasedQty = Number(qty) - 1;
    const newQty = oneDecreasedQty < 0 ? "" : oneDecreasedQty;
    updateForm((prevState) => {
      return {...prevState, qty: newQty};
    });
  };
  const handleFocus = (event) => {
    event.target.scrollIntoView({behavior: "smooth", block: "nearest"});
    event.target.select();
  };
  const handleCostChange = (newCost) => updateForm((prevState) => {
    return {...prevState, costPrice: newCost ? newCost : ""};
  });
  const handleTaxCodeChange = (newTaxCode) => updateForm((prevState) => {
    return {...prevState, taxCode: newTaxCode};
  });
  const handleQtyBackspace = (event) => {
    if (event.key === "Backspace" && qtyInputRef.current.value.length === 1) {
      updateForm((prevState) => {
        return {...prevState, qty: ""};
      });
    }
  };
  useEffect(() => () => setSelected(null), []);
  useEffect(() => {
    if (!isMobile || !isTablet) {
      setSelectedDelete(null);
      setIsLongPress(false);
    }
  }, [isMobile, isTablet]);
  const enableDelete = (e) => {
    e.preventDefault();
    const timestamp = e.target.closest(".table-row").getAttribute("data-pwadatecreated");
    setSelectedDelete(e.target.closest(".table-row").id);
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    if (isMobile || isTablet) {
      if (isWithinTheDay(+timestamp) && (isSellable == void 0 || isSellable != false)) {
        setIsLongPress(true);
      }
    } else {
      setIsLongPress(false);
      setSelectedDelete(null);
    }
  };
  const longPress = useLongPress(enableDelete, {
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false
  });
  const isWithinTheDay = (timestamp) => {
    const dateNow = new Date();
    const dateStartOfDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 0, 0, 0).getTime();
    const dateEndOfDay = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate(), 23, 59, 59).getTime();
    if (timestamp >= dateStartOfDay && timestamp <= dateEndOfDay) {
      return true;
    } else {
      return false;
    }
  };
  const handleOnClickRow = (index, timestamp) => {
    if (isLongPress && isWithinTheDay(+timestamp)) {
      setSelectedDelete(index);
    }
  };
  const handleExpandParent = (e) => {
    const parentRow = e.target.closest(".table-body-row");
    const parentId = parentRow.getAttribute("data-parentid");
    setExpandedParent((old) => {
      if (old.includes(parentId)) {
        return old.filter((o) => o !== parentId);
      } else {
        return [...old, parentId];
      }
    });
  };
  const clickOnLink = (childItem) => {
    const {parentBuyQty, sellerSku: sellerSku2} = childItem;
    const newSelected = list.filter((li) => li.sellerSku == sellerSku2)[0];
    if (newSelected) {
      setSelected(newSelected);
    }
    if (parentBuyQty > 0) {
      history.push(`/shopping-list/${sellerSku2}`);
    } else {
      history.push(`/shopped-list/${sellerSku2}`);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "bought-qty"
  }, /* @__PURE__ */ React.createElement(BuyListGroupSelection, {
    onBuyList: handleAddToCart,
    showBuyList,
    setShowBuyList,
    selectedItem,
    setSelectedItem,
    user
  }), /* @__PURE__ */ React.createElement(BuyListMergeSelection, {
    showBuyListMerge,
    setShowBuyListMerge,
    buyListArray,
    handleAddToCart,
    setSelectedBuyList: setSelectedItem,
    user
  }), isLoading ? /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true,
    type: "overlay"
  }) : null, /* @__PURE__ */ React.createElement("div", {
    className: "product-details"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "thumbnail",
    src: thumbnail
  }), /* @__PURE__ */ React.createElement("div", {
    className: "links"
  }, /* @__PURE__ */ React.createElement("a", {
    href: `${amazonUrl}/dp/${asin}`,
    target: "_blank",
    rel: "noopener noreferrer"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/assets/images/amazon-icon.svg"
  })), /* @__PURE__ */ React.createElement("a", {
    href: `https://keepa.com/#!product/1-${asin}`,
    target: "_blank",
    rel: "noopener noreferrer"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/assets/images/keepa-icon.svg"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "title"
  }, customTitle ?? title, " ", !isSellable == void 0 || isSellable == false ? "(Non Sellable)" : ""), /* @__PURE__ */ React.createElement("div", {
    className: "props group-1"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "ASIN"), /* @__PURE__ */ React.createElement("div", null, asin)), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Cost"), /* @__PURE__ */ React.createElement("div", null, costPrice ? numberToUSD(costPrice) : "N/A")), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "QTY"), /* @__PURE__ */ React.createElement("div", null, afnTotalQuantity))), /* @__PURE__ */ React.createElement("div", {
    className: "props group-2"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Supplier"), /* @__PURE__ */ React.createElement("div", null, supplier ?? "N/A")), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Store Section"), /* @__PURE__ */ React.createElement("div", null, storeSection ?? "N/A"))), /* @__PURE__ */ React.createElement("div", {
    className: "props group-3"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Notes"), /* @__PURE__ */ React.createElement("div", null, notesSection)), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Buy Link"), /* @__PURE__ */ React.createElement("div", null, purchaseLink ? purchaseLink.length ? purchaseLink.map((link) => /* @__PURE__ */ React.createElement("a", {
    key: link,
    href: link,
    target: "_blank"
  }, link)) : "N/A" : "N/A"))), /* @__PURE__ */ React.createElement("div", {
    className: "buy-qty"
  }, /* @__PURE__ */ React.createElement("label", null, "Buy Qty"), /* @__PURE__ */ React.createElement("span", null, isAlwaysBuyList ? "∞" : buyQty ? buyQty : 0))), /* @__PURE__ */ React.createElement("form", {
    className: "add-form",
    onSubmit: checkCartBuyList
  }, /* @__PURE__ */ React.createElement("label", {
    className: "field"
  }, /* @__PURE__ */ React.createElement("span", null, "Actual Qty"), /* @__PURE__ */ React.createElement("div", {
    className: "increments"
  }, /* @__PURE__ */ React.createElement("input", {
    name: "qty",
    type: "number",
    value: qty,
    min: 1,
    ref: qtyInputRef,
    required: true,
    onInput: onInputChange,
    onKeyDown: handleQtyBackspace,
    onFocus: handleFocus
  }), /* @__PURE__ */ React.createElement("button", {
    className: "minus",
    type: "button",
    onClick: decrementQty
  }, /* @__PURE__ */ React.createElement(Remove, {
    fontSize: "inherit"
  })), /* @__PURE__ */ React.createElement("button", {
    className: "plus",
    type: "button",
    onClick: incrementQty
  }, /* @__PURE__ */ React.createElement(Add, {
    fontSize: "inherit"
  })))), /* @__PURE__ */ React.createElement("label", {
    className: "field"
  }, /* @__PURE__ */ React.createElement("span", null, "Expiration Date"), /* @__PURE__ */ React.createElement("input", {
    name: "expiryDate",
    type: "date",
    value: expiryDate,
    onInput: onInputChange
  })), /* @__PURE__ */ React.createElement("label", {
    className: "field"
  }, /* @__PURE__ */ React.createElement("span", null, "Tax Code"), /* @__PURE__ */ React.createElement(TaxCodeInput, {
    selectedTaxCode: taxCode,
    setTaxCode: handleTaxCodeChange
  })), /* @__PURE__ */ React.createElement("label", {
    className: "field"
  }, /* @__PURE__ */ React.createElement("span", null, "Condition"), /* @__PURE__ */ React.createElement("select", {
    name: "condition",
    type: "number",
    value: condition,
    onChange: onInputChange
  }, /* @__PURE__ */ React.createElement("option", {
    value: "New"
  }, "New"), /* @__PURE__ */ React.createElement("option", {
    value: "Used"
  }, "Used"))), /* @__PURE__ */ React.createElement("label", {
    className: "field"
  }, /* @__PURE__ */ React.createElement("span", null, "Cost"), /* @__PURE__ */ React.createElement(CurrencyInput, {
    name: "costPrice",
    prefix: "$",
    value: formCost,
    onValueChange: handleCostChange,
    placeholder: "",
    required: !costPrice,
    onFocus: handleFocus
  })), /* @__PURE__ */ React.createElement("label", {
    className: "field"
  }, /* @__PURE__ */ React.createElement("span", null, "Buy List"), /* @__PURE__ */ React.createElement(DefaultBuylist, {
    user,
    selectedItem,
    setSelectedItem
  })), (isSellable != void 0 || isSellable == false) && /* @__PURE__ */ React.createElement("div", {
    className: "parent-items"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "section-title"
  }, "Parent Items"), /* @__PURE__ */ React.createElement("table", {
    className: "parent-items-table"
  }, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", {
    className: "table-header-row"
  }, /* @__PURE__ */ React.createElement("th", null, "Title"), /* @__PURE__ */ React.createElement("th", null, "ASIN"), /* @__PURE__ */ React.createElement("th", null, "Buy Qty"), /* @__PURE__ */ React.createElement("th", null, "Actual Qty"))), /* @__PURE__ */ React.createElement("tbody", null, parentItems.length > 0 ? parentItems.map((parent, index) => {
    if (expandedParent.includes(`parent${index}`)) {
      return /* @__PURE__ */ React.createElement(React.Fragment, {
        key: index
      }, /* @__PURE__ */ React.createElement("tr", {
        className: "table-body-row",
        style: {backgroundColor: expandedParent.includes(`parent${index}`) ? "white" : "var(--replen-lighter-gray)"},
        "data-parentid": `parent${index}`,
        onClick: handleExpandParent
      }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, parent.title)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, parent.asin)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, parent.decidedBuyQuantity ?? 0)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, (parent.childItems.find((ch) => ch.child == productChildId)?.quantity ?? 0) * (parent.decidedBuyQuantity ?? 0)), /* @__PURE__ */ React.createElement("button", {
        type: "button",
        className: "expand-button"
      }, /* @__PURE__ */ React.createElement("span", {
        className: "rotate"
      }, "▼"))))), parent.childItems.length > 0 && parent.childItems.map((ch, ind) => {
        const isNotALink = ch.child == productChildId;
        const childItem = childItems.filter((chi) => chi.childId === ch.child)[0];
        return /* @__PURE__ */ React.createElement("tr", {
          className: "table-body-row child",
          style: {backgroundColor: expandedParent.includes(`parent${index}`) ? "white" : "var(--replen-lighter-gray)"},
          key: ind
        }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", {
          className: isNotALink ? "normal-bullet" : "link-bullet"
        }, "•"), " ", /* @__PURE__ */ React.createElement("span", {
          className: isNotALink ? "normal-title" : "link-title",
          onClick: () => !isNotALink && clickOnLink(childItem)
        }, childItem?.title))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, ch.quantity * (parent.decidedBuyQuantity ?? 0))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null)));
      }));
    } else {
      return /* @__PURE__ */ React.createElement("tr", {
        className: "table-body-row",
        style: {backgroundColor: expandedParent.includes(`parent${index}`) ? "white" : "var(--replen-lighter-gray)"},
        "data-parentid": `parent${index}`,
        onClick: handleExpandParent,
        key: index
      }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, parent.title)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, parent.asin)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, parent.decidedBuyQuantity ?? 0)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, (parent.childItems.find((ch) => ch.child == productChildId)?.quantity ?? 0) * (parent.decidedBuyQuantity ?? 0)), /* @__PURE__ */ React.createElement("button", {
        type: "button",
        className: "expand-button"
      }, /* @__PURE__ */ React.createElement("span", null, "▼")))));
    }
  }) : fetchingParents ? /* @__PURE__ */ React.createElement("tr", {
    className: "table-body-row",
    style: {backgroundColor: "var(--replen-lighter-gray)"},
    onClick: handleExpandParent
  }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, "Fetching parent items")), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null))) : /* @__PURE__ */ React.createElement("tr", {
    className: "table-body-row",
    style: {backgroundColor: "var(--replen-lighter-gray)"},
    onClick: handleExpandParent
  }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null, "This item does not have a parent item.")), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("div", null)))))), /* @__PURE__ */ React.createElement("button", {
    className: "submit",
    type: "submit",
    disabled: isLoading
  }, isLoading ? "Adding..." : "Add to Local Inventory")), data?.inventoryMovements?.length > 0 && /* @__PURE__ */ React.createElement("div", {
    className: "added-list-container"
  }, /* @__PURE__ */ React.createElement(Toast, {
    isOpen,
    handleClose: () => setIsOpen(false),
    type: toastType
  }, toastMessage), /* @__PURE__ */ React.createElement("table", {
    className: "added-list"
  }, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", null, "Date"), /* @__PURE__ */ React.createElement("th", null, "QTY"), /* @__PURE__ */ React.createElement("th", null, "Exp. Date"), /* @__PURE__ */ React.createElement("th", null, "Tax Code"), /* @__PURE__ */ React.createElement("th", null, "Cost"), /* @__PURE__ */ React.createElement("th", null, "Buy List"), !isMobile && !isTablet && /* @__PURE__ */ React.createElement("th", null))), /* @__PURE__ */ React.createElement("tbody", null, data.inventoryMovements.map(({inQuantity, otherDetails, createdAt, pwaDateCreated, buyListId}, i) => {
    const {expiryDate: expiryDate2, taxCode: taxCode2, condition: condition2, costPrice: costPrice2} = otherDetails;
    return /* @__PURE__ */ React.createElement("tr", {
      key: i,
      ...longPress,
      onClick: () => handleOnClickRow(i, pwaDateCreated),
      style: {backgroundColor: i == selectedDelete && isWithinTheDay(+pwaDateCreated) && (isSellable == void 0 || isSellable != false) ? isMobile || isTablet ? "#F93402" : "unset" : "unset", color: i == selectedDelete && isWithinTheDay(+pwaDateCreated) ? (isMobile || isTablet) && (isSellable == void 0 || isSellable != false) ? "white" : "unset" : "unset"},
      id: i,
      className: "table-row",
      "data-pwadatecreated": pwaDateCreated
    }, /* @__PURE__ */ React.createElement("td", {
      className: "table-data"
    }, /* @__PURE__ */ React.createElement("div", null, new Date(pwaDateCreated ? +pwaDateCreated : createdAt).toLocaleDateString()), /* @__PURE__ */ React.createElement("div", null, new Date(pwaDateCreated ? +pwaDateCreated : createdAt).toLocaleTimeString())), /* @__PURE__ */ React.createElement("td", {
      className: "table-data"
    }, inQuantity, " ", /* @__PURE__ */ React.createElement("span", {
      className: condition2 === "New" ? "superscript-badge superscript-badge-new" : "superscript-badge superscript-badge-old"
    }, condition2)), /* @__PURE__ */ React.createElement("td", {
      className: "table-data"
    }, expiryDate2 ? DateTime.fromISO(expiryDate2).toLocaleString() : /* @__PURE__ */ React.createElement("span", {
      className: "not-set"
    }, "Not set")), /* @__PURE__ */ React.createElement("td", {
      className: "table-data"
    }, taxCode2 ? taxCode2 : /* @__PURE__ */ React.createElement("span", {
      className: "not-set"
    }, "Not set")), /* @__PURE__ */ React.createElement("td", {
      className: "table-data"
    }, costPrice2 ? numberToUSD(costPrice2) : /* @__PURE__ */ React.createElement("span", {
      className: "not-set"
    }, "Not set")), /* @__PURE__ */ React.createElement("td", {
      className: "table-data"
    }, buyListId?.name ? buyListId.name : /* @__PURE__ */ React.createElement("span", {
      className: "not-set"
    }, "Not set")), !isMobile && !isTablet && /* @__PURE__ */ React.createElement("td", null, (isSellable == void 0 || isSellable != false) && /* @__PURE__ */ React.createElement("button", {
      className: "remove",
      onClick: handleRemoveFromLocalInventory(i),
      style: {opacity: isWithinTheDay(+pwaDateCreated) ? "unset" : 0, pointerEvents: isWithinTheDay(+pwaDateCreated) ? "unset" : "none", cursor: "pointer"}
    }, /* @__PURE__ */ React.createElement(Close, null))));
  })))), /* @__PURE__ */ React.createElement(Toast, {
    isOpen: isToastOpen,
    handleClose: () => setIsToastOpen(false),
    type: toastType
  }, toastMessage));
};
export default BoughtQty;
