import * as __SNOWPACK_ENV__ from '../../../_snowpack/env.js';

import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {Switch, Route} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
import Spinner from "../spinner/index.js";
import OfflinePage from "../offline-page/index.js";
import LoggedOutContainer from "../../containers/logged-out/index.js";
import LoggedInContainer from "../../containers/logged-in/index.js";
import "./app.css";
const App = () => {
  const [user, setUser] = useState(null);
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const [isCheckUserFailed, setIsCheckUserFailed] = useState(false);
  const isDeviceAnIOS = () => {
    const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);
    const iOS13_iPad = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
    const iOS1to12quirk = function() {
      var audio = new Audio();
      audio.volume = 0.5;
      return audio.volume === 1;
    };
    const isIOS = !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());
    return isIOS;
  };
  const updateViewportHeightVar = () => {
    const rootElementStyle = document.querySelector("html").style;
    const height = window.visualViewport ? window.visualViewport.height : window.innerHeight;
    rootElementStyle.setProperty("--viewport-height", height + "px");
  };
  const setAuthorization = (token) => {
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
  };
  const unsetAuthorization = () => {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("loginToken");
  };
  const handleLogout = () => {
    localStorage.removeItem("email");
    unsetAuthorization();
    setUser(null);
  };
  const handleLoginSuccess = (loginToken, user2) => {
    localStorage.setItem("loginToken", loginToken);
    localStorage.setItem("email", user2.email);
    localStorage.setItem("newEmail", "");
    localStorage.setItem("password", "");
    setAuthorization(loginToken);
    setUser(user2);
  };
  const setDefaultHeaders = () => {
    const REPLEN_API_URL = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_REPLEN_API_URL;
    axios.defaults.baseURL = REPLEN_API_URL;
    axios.defaults.headers.get["Content-Type"] = "application/json";
    axios.defaults.headers.post["Content-Type"] = "application/json";
  };
  const checkUser = async () => {
    const loginToken = localStorage.getItem("loginToken");
    try {
      if (loginToken) {
        setAuthorization(loginToken);
        const response = await axios.get("/api/v1/findUser");
        if (response.status === 200) {
          const {user: user2} = response.data;
          localStorage.setItem("email", user2.email);
          setUser(user2);
          setIsCheckUserFailed(false);
        }
      }
    } catch (error) {
      switch (error?.response?.status) {
        case 400:
          unsetAuthorization();
          break;
        default:
          setIsCheckUserFailed(true);
          addOnlineListener();
      }
    } finally {
      setIsTokenChecked(true);
    }
  };
  const handleReconnect = () => {
    setIsTokenChecked(false);
    checkUser();
  };
  const addOnlineListener = () => {
    window.addEventListener("online", handleReconnect, {once: true});
  };
  useEffect(() => {
    if (isDeviceAnIOS()) {
      const viewportMetaTag = document.querySelector("meta[name=viewport]");
      viewportMetaTag.content += ", maximum-scale=1.0";
    }
    setDefaultHeaders();
    checkUser();
    void function updateLoop() {
      updateViewportHeightVar();
      setTimeout(() => updateLoop(), 100);
    }();
    window.addEventListener("scroll", () => window.scrollTo(0, 0));
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "app"
  }, isTokenChecked ? isCheckUserFailed ? /* @__PURE__ */ React.createElement(OfflinePage, {
    handleReconnect
  }) : /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/auth",
    render: (p) => /* @__PURE__ */ React.createElement(LoggedOutContainer, {
      ...p,
      user,
      handleLoginSuccess
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/",
    render: (p) => /* @__PURE__ */ React.createElement(LoggedInContainer, {
      ...p,
      user,
      handleLogout,
      handleLoginSuccess
    })
  })) : localStorage.loginToken ? /* @__PURE__ */ React.createElement(Spinner, {
    size: 100,
    isCentered: true
  }) : null);
};
export default App;
