import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import axios from "../../../_snowpack/pkg/axios.js";
import UnresolvedListItem from "./unresolved-list-item/index.js";
import ConfirmResolve from "./resolve-modal.js";
import Spinner from "../spinner/index.js";
import "./unresolved-products.css";
const UnresolvedProducts = () => {
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios.get("/api/v1/child-inventory/unresolved").then(({data}) => {
      setRows(data.data);
      setIsLoading(false);
    });
  }, []);
  return /* @__PURE__ */ React.createElement("div", {
    className: "unresolved-products"
  }, /* @__PURE__ */ React.createElement(ConfirmResolve, {
    open: openModal,
    setOpen: setOpenModal,
    item,
    setItem,
    setRows,
    setIsLoading
  }), isLoading && /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true,
    type: "overlay"
  }), rows.length > 0 && rows.map((row, index) => /* @__PURE__ */ React.createElement(UnresolvedListItem, {
    row,
    key: index,
    setOpenModal,
    setItem
  })));
};
export default UnresolvedProducts;
