import React from "../../../_snowpack/pkg/react.js";
import {NavLink, useRouteMatch} from "../../../_snowpack/pkg/react-router-dom.js";
import {Assignment, Dashboard, Settings, Person, ExitToApp, ExpandLess, AssignmentTurnedIn} from "../../../_snowpack/pkg/@material-ui/icons.js";
import "./side-nav.css";
const SideNav = ({state, handlers}) => {
  const {user, isUserMenuOpen, newCartItems} = state;
  const {handleLogout, handleUserMenuToggle} = handlers;
  const {path: pathInventory} = useRouteMatch("/inventory/:productId") ?? {};
  const selectedInventory = localStorage.getItem("selectedInventory");
  return /* @__PURE__ */ React.createElement("nav", {
    className: "side-nav"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "nav-contents"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "nav-links"
  }, /* @__PURE__ */ React.createElement(NavLink, {
    to: "/dashboard",
    activeClassName: "active"
  }, /* @__PURE__ */ React.createElement(Dashboard, null), " Dashboard"), /* @__PURE__ */ React.createElement(NavLink, {
    to: selectedInventory ? pathInventory ? `/inventory` : `/inventory/${selectedInventory}` : `/inventory`,
    activeClassName: "active"
  }, /* @__PURE__ */ React.createElement(AssignmentTurnedIn, null), " Inventory"), /* @__PURE__ */ React.createElement(NavLink, {
    to: "/shopping-list",
    activeClassName: "active"
  }, /* @__PURE__ */ React.createElement(Assignment, null), " Shopping List"), /* @__PURE__ */ React.createElement(NavLink, {
    to: "/settings",
    activeClassName: "active"
  }, /* @__PURE__ */ React.createElement(Settings, null), " Settings")), /* @__PURE__ */ React.createElement("div", {
    className: `user-menu${isUserMenuOpen ? " is-open" : ""}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "user-dropdown-contents"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: handleLogout
  }, /* @__PURE__ */ React.createElement(ExitToApp, null), " Logout")), /* @__PURE__ */ React.createElement("button", {
    className: "user-dropdown-button",
    onClick: handleUserMenuToggle
  }, /* @__PURE__ */ React.createElement(Person, null), /* @__PURE__ */ React.createElement("span", null, user?.name), /* @__PURE__ */ React.createElement(ExpandLess, null)))));
};
export default SideNav;
