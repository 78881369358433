import React from "../../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../../_snowpack/pkg/react-router-dom.js";
import "./unresolved-list-item.css";
const UnresolvedListItem = ({row, setOpenModal, setItem}) => {
  const {title, imageUrl, asin, sellerSku, _id: childId} = row;
  const history = useHistory();
  const onClickResolve = () => {
    setOpenModal(true);
    setItem(row);
  };
  const onClickPack = () => {
    history.push(`/unresolved-products/pack/${childId}`);
  };
  const thumbnail = imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  return /* @__PURE__ */ React.createElement("div", {
    className: "grid"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "product-info"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "product-image-title"
  }, /* @__PURE__ */ React.createElement("img", {
    src: thumbnail,
    alt: title
  }), /* @__PURE__ */ React.createElement("p", null, title)), /* @__PURE__ */ React.createElement("div", {
    className: "product-asin-sku"
  }, /* @__PURE__ */ React.createElement("p", null, asin), /* @__PURE__ */ React.createElement("p", null, sellerSku))), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: onClickPack
  }, "Pack"), /* @__PURE__ */ React.createElement("button", {
    onClick: onClickResolve
  }, "Resolve")));
};
export default UnresolvedListItem;
