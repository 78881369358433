import React from "../../../../../_snowpack/pkg/react.js";
import {Accordion, AccordionSummary, AccordionDetails} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ArrowDropDown} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import "./accordionFlex.css";
const ThirtyDays = ({expanded, setExpanded, product}) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {buyQuantity30Days, totalOrdersLast30Days, salesLast30Days, salesAmountLast30Days, totalPendingOrdersLast30Days, afnTotalQuantity, localInventory, decidedBuyQuantity} = product;
  let adjusted30BuyQty = 0;
  if (!isNaN(buyQuantity30Days)) {
    adjusted30BuyQty = buyQuantity30Days;
    if (!isNaN(localInventory)) {
      adjusted30BuyQty = buyQuantity30Days - localInventory;
    }
    if (!isNaN(decidedBuyQuantity)) {
      adjusted30BuyQty = adjusted30BuyQty - decidedBuyQuantity;
    }
  }
  const expandedRows = [
    {label: "orders", value: totalOrdersLast30Days ?? 0},
    {label: "units", value: salesLast30Days ?? 0},
    {label: "sale amount", value: `$${salesAmountLast30Days ?? 0}`},
    {label: "pending orders", value: totalPendingOrdersLast30Days ?? 0},
    {label: "inventory qty", value: afnTotalQuantity ?? 0},
    {label: "local qty", value: localInventory ?? 0},
    {label: "recommended buy list adj", value: adjusted30BuyQty ?? 0}
  ];
  return /* @__PURE__ */ React.createElement(Accordion, {
    expanded: expanded === "panel2",
    onChange: handleChange("panel2"),
    style: {borderBottom: "1px solid var(--replen-light-bg)"}
  }, /* @__PURE__ */ React.createElement(AccordionSummary, {
    expandIcon: /* @__PURE__ */ React.createElement(ArrowDropDown, null),
    "aria-controls": "panel-content",
    id: "panel-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-summary"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "30 Days"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, adjusted30BuyQty ?? 0))), /* @__PURE__ */ React.createElement(AccordionDetails, {
    id: "panel-body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body"
  }, expandedRows.map((row, index) => /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body-row",
    key: index
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, row.label, ":"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, row.value))))));
};
export default ThirtyDays;
