import React, {useState} from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
import Card from "../card/index.js";
import Form from "../form/index.js";
import Toast from "../toast/index.js";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("error");
  const fields = [
    {
      label: "Email Address",
      fieldProps: {
        type: "email",
        value: email,
        name: "email",
        onInput: (e) => setEmail(e.target.value)
      }
    }
  ];
  const onSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setIsSending(true);
      axios.post("/api/v1/forgot-password-pwa", {email}).then((res) => {
        setIsSending(false);
        setToastMessage(`Reset password link was sent to ${email}.`);
        setToastType("success");
        setIsOpen(true);
      }).catch((err) => {
        setIsSending(false);
        setToastMessage(`User with email address ${email} was not found.`);
        setToastType("error");
        setIsOpen(true);
      });
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, null, /* @__PURE__ */ React.createElement("div", {
    className: "title"
  }, "Reset Password"), /* @__PURE__ */ React.createElement(Form, {
    fields,
    submitText: "Reset Password",
    onSubmit,
    isSending
  }), /* @__PURE__ */ React.createElement("p", null, " ", /* @__PURE__ */ React.createElement(Link, {
    to: "/auth/login",
    className: "link-orange"
  }, "Login"), " to my account")), /* @__PURE__ */ React.createElement(Toast, {
    isOpen,
    handleClose: () => setIsOpen(false),
    type: toastType
  }, toastMessage));
};
export default ForgotPassword;
