import React from "../../../_snowpack/pkg/react.js";
import {NavLink, useRouteMatch} from "../../../_snowpack/pkg/react-router-dom.js";
import {Dashboard, List, Delete, AssignmentTurnedIn} from "../../../_snowpack/pkg/@material-ui/icons.js";
import "./bottom-nav.css";
const BottomNav = ({isLongPress, setIsLongPress, pathname, deleteFunction, selectedDelete, setSelectedDelete}) => {
  const handleDelete = (e) => {
    e.preventDefault();
    if (selectedDelete !== null) {
      deleteFunction(selectedDelete);
    }
    setSelectedDelete(null);
    setIsLongPress(false);
  };
  const match = useRouteMatch("/shopped-list/:sellerSku/:ext") || useRouteMatch("/shopping-list/:sellerSku/:ext") || useRouteMatch("/shopped-list/:sellerSku") || useRouteMatch("/shopping-list/:sellerSku");
  const {path: pathInventory} = useRouteMatch("/inventory/:productId") ?? {};
  const selectedInventory = localStorage.getItem("selectedInventory");
  return /* @__PURE__ */ React.createElement("nav", {
    className: "bottom-nav"
  }, match && match.isExact && isLongPress ? /* @__PURE__ */ React.createElement(NavLink, {
    to: "/",
    onClick: handleDelete
  }, /* @__PURE__ */ React.createElement(Delete, null), " Delete") : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NavLink, {
    to: "/dashboard",
    activeClassName: "active"
  }, /* @__PURE__ */ React.createElement(Dashboard, null), " Dashboard"), /* @__PURE__ */ React.createElement(NavLink, {
    to: selectedInventory ? pathInventory ? `/inventory` : `/inventory/${selectedInventory}` : `/inventory`,
    activeClassName: "active"
  }, /* @__PURE__ */ React.createElement(AssignmentTurnedIn, null), " Inventory"), /* @__PURE__ */ React.createElement(NavLink, {
    to: "/shopping-list",
    activeClassName: "active"
  }, /* @__PURE__ */ React.createElement(List, null), " Shopping List")));
};
export default BottomNav;
