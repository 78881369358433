import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import "./settings.css";
import axios from "../../../_snowpack/pkg/axios.js";
import Toast from "../toast/index.js";
import checkPassword from "../../utilities/js/password-checker.js";
import {Button} from "../../../_snowpack/pkg/@material-ui/core.js";
const Settings = ({setIsRefetchCheckUserEnabled, handleLogout}) => {
  const savedEmail = localStorage.getItem("email");
  const [email, setEmail] = useState(savedEmail ? savedEmail : "");
  const [editEmail, setEditEmail] = useState(false);
  const [editEmailPassword, setEditEmailPassword] = useState("");
  const [showEditEmailPassword, setShowEditEmailPassword] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [toastType, setToastType] = useState("error");
  const [updateEmailLoading, setUpdateEmailLoading] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  useEffect(() => {
    localStorage.setItem("password", "");
    localStorage.setItem("newEmail", "");
  }, []);
  const handleUpdateEmail = (e) => {
    e.preventDefault();
    setUpdateEmailLoading(true);
    if (editEmailPassword) {
      axios.post("/api/v1/update-email", {email: savedEmail, newEmail: email, password: editEmailPassword}).then((res) => {
        setUpdateEmailLoading(false);
        if (res.data.msg === "success") {
          setToastMsg("");
          setEditEmail(false);
          localStorage.setItem("newEmail", email);
          localStorage.setItem("password", editEmailPassword);
          setIsRefetchCheckUserEnabled(true);
          setOpenToast(true);
          setToastType("success");
          setToastMsg(`A confirmation email was sent to the email address ${savedEmail}.`);
        } else if (res.data.msg === "New Email Address is already taken.") {
          setOpenToast(true);
          setToastMsg(res.data.msg);
          setToastType("error");
          setEmail(savedEmail);
        }
      }).catch((err) => {
        setUpdateEmailLoading(false);
        setOpenToast(true);
        setToastMsg("Incorrect Password");
        setToastType("error");
      });
    }
  };
  const enableEditEmail = () => {
    setEditEmail((old) => !old);
    setEditEmailPassword("");
  };
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    setUpdatePasswordLoading(true);
    const requestBody = {
      email: savedEmail,
      currentPassword,
      newPassword
    };
    axios.post("/api/v1/update-password-pwa", requestBody).then((res) => {
      const {data} = res;
      const {status, message} = data;
      setUpdatePasswordLoading(false);
      if (status === "success") {
        setEditPassword(false);
        setOpenToast(true);
        setToastType("success");
        setToastMsg(`${message} Redirecting to login page.`);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setTimeout(() => {
          handleLogout();
        }, 1500);
      } else if (status === "failed") {
        setCurrentPasswordError(message);
      }
    }).catch((err) => {
      setUpdatePasswordLoading(false);
    });
  };
  const enableEditPassword = () => {
    setEditPassword((old) => !old);
  };
  const currentPasswordOnChange = (e) => {
    setCurrentPassword(e.target.value);
    setCurrentPasswordError("");
  };
  const newPasswordOnChange = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length < 8) {
      setNewPasswordError("Password must be atleast 8 characters.");
    } else {
      if (checkPassword(e.target.value)) {
        setNewPasswordError("");
      } else {
        setNewPasswordError("Password must contain atleast one uppercase, lowercase, number and special character.");
      }
    }
    if (e.target.value !== confirmNewPassword) {
      setConfirmNewPasswordError("Please make sure your passwords match.");
    } else {
      setConfirmNewPasswordError("");
    }
  };
  const confirmNewPasswordOnChange = (e) => {
    setConfirmNewPassword(e.target.value);
    if (e.target.value !== newPassword) {
      setConfirmNewPasswordError("Please make sure your passwords match.");
    } else {
      setConfirmNewPasswordError("");
    }
  };
  const cancelEditEmail = () => {
    setEditEmail(false);
    setEmail(savedEmail);
  };
  const cancelEditPassword = () => {
    setEditPassword(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };
  const canUpdateEmail = () => {
    if (editEmailPassword) {
      return true;
    } else {
      return false;
    }
  };
  const canUpdatePassword = () => {
    return currentPassword && !currentPasswordError && newPassword && !newPasswordError && confirmNewPassword && !confirmNewPasswordError;
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "settings"
  }, /* @__PURE__ */ React.createElement(Toast, {
    isOpen: openToast,
    handleClose: () => setOpenToast(false),
    type: toastType,
    duration: 3e3
  }, toastMsg), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleUpdateEmail
  }, !editEmail && /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "email"
  }, "Email Address"), /* @__PURE__ */ React.createElement("div", {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "email",
    id: "email",
    value: email,
    disabled: true
  }), /* @__PURE__ */ React.createElement("button", {
    className: "edit-input-button",
    type: "button",
    onClick: enableEditEmail
  }, "Edit"))), editEmail && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "email"
  }, "Email Address", /* @__PURE__ */ React.createElement("span", {
    style: {color: "red"}
  }, "*")), /* @__PURE__ */ React.createElement("input", {
    type: "email",
    id: "email",
    value: email,
    onChange: (e) => setEmail(e.target.value)
  })), /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "email-password"
  }, "Enter Password to Confirm", /* @__PURE__ */ React.createElement("span", {
    style: {color: "red"}
  }, "*")), /* @__PURE__ */ React.createElement("div", {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement("input", {
    type: showEditEmailPassword ? "text" : "password",
    id: "email-password",
    value: editEmailPassword,
    onChange: (e) => setEditEmailPassword(e.target.value),
    style: {borderColor: "var(--replen-gray)"}
  }), /* @__PURE__ */ React.createElement("button", {
    className: "edit-input-button",
    type: "button",
    onClick: () => setShowEditEmailPassword((old) => !old)
  }, showEditEmailPassword ? "Hide" : "Show"))), /* @__PURE__ */ React.createElement("div", {
    className: "row",
    style: {flexDirection: "row", justifyContent: "flex-end"}
  }, /* @__PURE__ */ React.createElement("button", {
    className: "cancel-button",
    type: "button",
    onClick: cancelEditEmail
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    className: "save-button",
    style: {opacity: canUpdateEmail() ? updateEmailLoading ? 0.5 : 1 : 0.5, pointerEvents: canUpdateEmail() ? updateEmailLoading ? "none" : "unset" : "none"}
  }, "Save")))), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleUpdatePassword,
    style: {marginTop: "1rem"}
  }, !editPassword && /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "password-placeholder"
  }, "Password"), /* @__PURE__ */ React.createElement("div", {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "password",
    id: "password-placeholder",
    value: "password",
    disabled: true
  }), /* @__PURE__ */ React.createElement("button", {
    className: "edit-input-button",
    type: "button",
    onClick: enableEditPassword
  }, "Edit"))), editPassword && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "current-password"
  }, "Current Password", /* @__PURE__ */ React.createElement("span", {
    style: {color: "red"}
  }, "*")), /* @__PURE__ */ React.createElement("div", {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement("input", {
    type: showCurrentPassword ? "text" : "password",
    id: "current-password",
    value: currentPassword,
    onChange: currentPasswordOnChange
  }), /* @__PURE__ */ React.createElement("button", {
    className: "edit-input-button",
    type: "button",
    onClick: () => setShowCurrentPassword((old) => !old)
  }, showCurrentPassword ? "Hide" : "Show")), /* @__PURE__ */ React.createElement("p", {
    className: "error-message"
  }, currentPasswordError)), /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "new-password"
  }, "New Password", /* @__PURE__ */ React.createElement("span", {
    style: {color: "red"}
  }, "*")), /* @__PURE__ */ React.createElement("div", {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement("input", {
    type: showNewPassword ? "text" : "password",
    id: "new-password",
    value: newPassword,
    onChange: newPasswordOnChange
  }), /* @__PURE__ */ React.createElement("button", {
    className: "edit-input-button",
    type: "button",
    onClick: () => setShowNewPassword((old) => !old)
  }, showNewPassword ? "Hide" : "Show")), /* @__PURE__ */ React.createElement("p", {
    className: "error-message"
  }, newPasswordError)), /* @__PURE__ */ React.createElement("div", {
    className: "row"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "confirm-new-password"
  }, "Confirm New Password", /* @__PURE__ */ React.createElement("span", {
    style: {color: "red"}
  }, "*")), /* @__PURE__ */ React.createElement("div", {
    className: "input-container"
  }, /* @__PURE__ */ React.createElement("input", {
    type: showConfirmNewPassword ? "text" : "password",
    id: "confirm-new-password",
    value: confirmNewPassword,
    onChange: confirmNewPasswordOnChange
  }), /* @__PURE__ */ React.createElement("button", {
    className: "edit-input-button",
    type: "button",
    onClick: () => setShowConfirmNewPassword((old) => !old)
  }, showConfirmNewPassword ? "Hide" : "Show")), /* @__PURE__ */ React.createElement("p", {
    className: "error-message"
  }, confirmNewPasswordError)), /* @__PURE__ */ React.createElement("div", {
    className: "row",
    style: {flexDirection: "row", justifyContent: "flex-end"}
  }, /* @__PURE__ */ React.createElement("button", {
    className: "cancel-button",
    type: "button",
    onClick: cancelEditPassword
  }, "Cancel"), /* @__PURE__ */ React.createElement("button", {
    className: "save-button",
    style: {opacity: canUpdatePassword() ? updatePasswordLoading ? 0.5 : 1 : 0.5, pointerEvents: canUpdatePassword() ? updatePasswordLoading ? "none" : "unset" : "none"}
  }, "Save")))));
};
export default Settings;
