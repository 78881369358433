import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import {useBuyLitsItems} from "./useBuyListItemHelper.js";
import {ArrowForward} from "../../../_snowpack/pkg/@material-ui/icons.js";
import {Button, Divider, FormControlLabel, Switch} from "../../../_snowpack/pkg/@material-ui/core.js";
import Spinner from "../spinner/index.js";
import "./buy-list-item.css";
import numberToUSD from "../../utilities/js/number-to-usd.js";
import useMediaQuery from "../../../_snowpack/pkg/@material-ui/core/useMediaQuery.js";
import {formatValue} from "../../../_snowpack/pkg/react-currency-input-field.js";
import {groupBy} from "../../../_snowpack/pkg/lodash.js";
import moment from "../../../_snowpack/pkg/moment.js";
const BuyListItems = ({buylist, toggleDrawer, user}) => {
  const {isLoading, isFetching, data: buyListData} = useBuyLitsItems(buylist.userId, buylist._id, user);
  const minWidth600 = useMediaQuery("(min-width:600px)");
  const minWidth720 = useMediaQuery("(min-width:720px)");
  const maxWidth1024 = useMediaQuery("(max-width:1024px)");
  const [consolidate, setConsolidate] = useState(false);
  const [consoBuyList, setConsoBuyList] = useState([]);
  const totalBuyListCost = consoBuyList ? consoBuyList.reduce((total, item) => total + item.quantity * item.costUnit, 0) : 0;
  const totalBuyListQtyQty = consoBuyList ? consoBuyList.reduce((total, item) => total + item.quantity, 0) : 0;
  useEffect(() => {
    if (consolidate) {
      setConsoBuyList(consolidateBuylist(buyListData));
    } else {
      setConsoBuyList(buyListData);
    }
  }, [consolidate, buyListData]);
  const consolidateBuylist = (arr) => {
    let groupSku = groupBy(arr, (list) => list.msku);
    groupSku = Object.values(groupSku);
    groupSku = groupSku.map((x) => {
      const avg = x.reduce((total, item) => {
        if (item.costUnit) {
          total.cost += item.costUnit * item.quantity;
        }
        if (item.quantity) {
          total.quantity += item.quantity;
        }
        return total;
      }, {cost: 0, quantity: 0});
      avg.cost = avg.cost / avg.quantity || 0;
      avg.quantity = avg.quantity / x.length;
      const result = x.reduce((total, item) => {
        total.costUnit += (item.costUnit || avg.cost) * item.quantity;
        total.quantity += item.quantity;
        const temp = new Date(total.expirationDate) < new Date(item.expirationDate) ? total.expirationDate || item.expirationDate : item.expirationDate;
        if (temp)
          total.expirationDate = temp;
        const condition = total.condition !== "Used" && item.condition === "Used" ? "Used" : "New";
        if (condition)
          total.condition = condition;
        return total;
      }, {...JSON.parse(JSON.stringify(x[0])), costUnit: 0, quantity: 0});
      if (result.quantity > 0) {
        result.costUnit = result.costUnit / result.quantity || 0;
      } else {
        result.costUnit = 0;
      }
      return result;
    });
    return groupSku;
  };
  const handleCheck = (event) => {
    setConsolidate(event.target.checked);
  };
  const formatCost = (cost) => formatValue({
    value: String(cost),
    prefix: "$",
    decimalScale: 2
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "buy-list-header"
  }, !isLoading && !isFetching && /* @__PURE__ */ React.createElement("div", {
    className: minWidth600 || minWidth720 && maxWidth1024 ? "nav-hide" : "nav-show"
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "back-btn",
    size: "large"
  }, /* @__PURE__ */ React.createElement(ArrowForward, {
    fontSize: "large",
    onClick: toggleDrawer(buylist, false)
  }))), !isLoading && !isFetching && /* @__PURE__ */ React.createElement("div", {
    className: "buy-list-title"
  }, `"${buylist.name}"`)), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(FormControlLabel, {
    style: {alignSelf: "center"},
    control: /* @__PURE__ */ React.createElement(Switch, {
      checked: consolidate,
      onChange: handleCheck,
      name: "checkedA"
    }),
    color: "secondary",
    label: "Consolidate purchases for the same product SKU"
  }), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement("div", {
    className: "buy-list-container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "buy-list-items"
  }, isLoading || isFetching ? /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true,
    type: "overlay"
  }) : consoBuyList && consoBuyList?.map((buyList, i) => {
    const {imageUrl, title, customTitle, asin, quantity, expirationDate, condition, costUnit} = buyList;
    const thumbnail = imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
    return /* @__PURE__ */ React.createElement("div", {
      key: i,
      className: "shopping-list-item"
    }, /* @__PURE__ */ React.createElement("img", {
      className: "thumbnail",
      src: thumbnail
    }), /* @__PURE__ */ React.createElement("div", {
      className: "title"
    }, customTitle ?? title), /* @__PURE__ */ React.createElement("div", {
      className: "props"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "prop"
    }, /* @__PURE__ */ React.createElement("label", null, "ASIN"), /* @__PURE__ */ React.createElement("div", null, asin)), /* @__PURE__ */ React.createElement("div", {
      className: "prop"
    }, /* @__PURE__ */ React.createElement("label", null, "Cost"), /* @__PURE__ */ React.createElement("div", null, costUnit ? numberToUSD(costUnit) : "N/A")), /* @__PURE__ */ React.createElement("div", {
      className: "prop"
    }, /* @__PURE__ */ React.createElement("label", null, "QTY"), /* @__PURE__ */ React.createElement("div", null, quantity)), /* @__PURE__ */ React.createElement("div", {
      className: "prop"
    }, /* @__PURE__ */ React.createElement("label", null, "Condition"), /* @__PURE__ */ React.createElement("div", null, condition ?? "N/A")), /* @__PURE__ */ React.createElement("div", {
      className: "prop"
    }, /* @__PURE__ */ React.createElement("label", null, "Expiry Date"), /* @__PURE__ */ React.createElement("div", null, expirationDate ? expirationDate?.trim() === "" ? "N/A" : moment(expirationDate).local().format("ll") : "N/A"))));
  }), buyListData?.length === 0 && /* @__PURE__ */ React.createElement("div", {
    className: "empty-buy-list"
  }, "This buy list is empty"))), !isLoading || isFetching ? /* @__PURE__ */ React.createElement("div", {
    className: "buy-list-footer"
  }, "Total Cost :", /* @__PURE__ */ React.createElement("span", {
    className: "total-cost"
  }, " ", formatCost(totalBuyListCost)), /* @__PURE__ */ React.createElement("span", {
    className: "total-qty"
  }, " (", totalBuyListQtyQty, " ", totalBuyListQtyQty === 1 ? "item" : "items", ")")) : null);
};
export default BuyListItems;
