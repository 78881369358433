import React from "../../../../../_snowpack/pkg/react.js";
import {Accordion, AccordionSummary, AccordionDetails} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ArrowDropDown} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import "./accordionFlex.css";
const NinetyDays = ({expanded, setExpanded, product}) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {buyQuantity90Days, totalOrdersLast90Days, salesLast90Days, salesAmountLast90Days, totalPendingOrdersLast90Days, afnTotalQuantity, localInventory, decidedBuyQuantity} = product;
  let adjusted90BuyQty = 0;
  if (!isNaN(buyQuantity90Days)) {
    adjusted90BuyQty = buyQuantity90Days;
    if (!isNaN(localInventory)) {
      adjusted90BuyQty = buyQuantity90Days - localInventory;
    }
    if (!isNaN(decidedBuyQuantity)) {
      adjusted90BuyQty = adjusted90BuyQty - decidedBuyQuantity;
    }
  }
  const expandedRows = [
    {label: "orders", value: totalOrdersLast90Days ?? 0},
    {label: "units", value: salesLast90Days ?? 0},
    {label: "sale amount", value: `$${salesAmountLast90Days ?? 0}`},
    {label: "pending orders", value: totalPendingOrdersLast90Days ?? 0},
    {label: "inventory qty", value: afnTotalQuantity ?? 0},
    {label: "local qty", value: localInventory ?? 0},
    {label: "recommended buy list adj", value: adjusted90BuyQty ?? 0}
  ];
  return /* @__PURE__ */ React.createElement(Accordion, {
    expanded: expanded === "panel4",
    onChange: handleChange("panel4"),
    style: {borderBottom: "1px solid var(--replen-light-bg)"}
  }, /* @__PURE__ */ React.createElement(AccordionSummary, {
    expandIcon: /* @__PURE__ */ React.createElement(ArrowDropDown, null),
    "aria-controls": "panel-content",
    id: "panel-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-summary"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "90 Days"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, adjusted90BuyQty ?? 0))), /* @__PURE__ */ React.createElement(AccordionDetails, {
    id: "panel-body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body"
  }, expandedRows.map((row, index) => /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body-row",
    key: index
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, row.label, ":"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, row.value))))));
};
export default NinetyDays;
