const dateNow = new Date()

export const chooseDefaultCalculationPrice = ({
    profitCalculatedBy,
    listPrice,
    buyBoxPrice,
    lowestOfferPrice,
    lowestFBAOfferPrice,
  }) => {
    if (profitCalculatedBy === "buyBoxPrice") return buyBoxPrice || lowestFBAOfferPrice || listPrice;
    if (profitCalculatedBy === "lowestOfferPrice") return lowestOfferPrice || listPrice;
    if (profitCalculatedBy === "lowestFBAOfferPrice") return lowestFBAOfferPrice || buyBoxPrice || listPrice;
    return listPrice;
  };

export const GetProfitEstimations = (updatedData) => {
  const {
      costPrice,
      defaultPrice,
      fee,
      fulfilmentType,
      shipBy,
      shippingRate,
      prepCenterFees,
      weight,
      fbmFee
  } = updatedData;

  let feeses = 0;

  if (fee) {
    if (defaultPrice > 0 && fee.feePercentage) {
      feeses += Math.round((defaultPrice * (fee.feePercentage / 100) + Number.EPSILON)*100)/100;
    }

    if(fee.otherFees) {
      feeses += Math.round((fee.otherFees+Number.EPSILON)*100)/100;
    }

    if (fulfilmentType === 'Amazon' || fulfilmentType === 'AMAZON') {
      if ([9, 10, 11].includes(dateNow.getMonth())) {
        if (fee.monthlyStorageFeeOct) {
          feeses += Math.round((fee.monthlyStorageFeeOct+Number.EPSILON)*100)/100;
        }
      } else if (fee.monthlyStorageFeeJan) {
        feeses += Math.round((fee.monthlyStorageFeeJan+Number.EPSILON)*100)/100;
      }
    }else
    {
      feeses += fbmFee??0;
    }
  }

  let totalCosts = 0;
  if (costPrice > 0) {
    totalCosts += costPrice;
  }

  if (fulfilmentType === 'Amazon' || fulfilmentType === 'AMAZON') {
    if (shipBy === 'byItem') {
      if (shippingRate > 0) {
        totalCosts += shippingRate;
      }
    } else if (shipBy === 'byWeight') {
      if (shippingRate > 0 && weight > 0) {
        totalCosts += (shippingRate * weight);
      }
    }
  }

  prepCenterFees ? totalCosts += prepCenterFees : totalCosts;
  const calculation = defaultPrice - feeses - totalCosts;
  const profit = Math.round((calculation+Number.EPSILON)*100)/100 || 0;
  let retObj = { profit };
  if (profit !== null) {
    const profitPercentage = defaultPrice > 0 ? Math.round((((profit / defaultPrice) * 100)+Number.EPSILON)*100)/100 : 0;
    const roiPercentage = costPrice > 0 ? Math.round((((profit / costPrice) * 100)+Number.EPSILON)*100)/100 : 0;
    retObj = {...retObj, profitPercentage, roiPercentage }
  }

  return retObj;
};