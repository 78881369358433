import React from "../../../_snowpack/pkg/react.js";
import "./form.css";
function Form({fields, submitText, onSubmit, isSending}) {
  const buttonClass = `submit-button${isSending ? " is-sending" : ""}`;
  return /* @__PURE__ */ React.createElement("form", {
    className: "form",
    onSubmit
  }, fields.map(({label, errorMessage, fieldProps, isCentered = false, isMuted = false, isSmall = false}, i) => {
    const centered = isCentered ? " is-centered" : "";
    const muted = isMuted ? " is-muted" : "";
    const small = isSmall ? " is-small" : "";
    const className = `form-field${centered}${muted}${small}`;
    return fieldProps.type === "checkbox" ? /* @__PURE__ */ React.createElement("div", {
      className: className + " is-regular",
      key: i
    }, /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("input", {
      ...fieldProps
    }), " ", label)) : /* @__PURE__ */ React.createElement("div", {
      className,
      key: i
    }, /* @__PURE__ */ React.createElement("label", null, label), /* @__PURE__ */ React.createElement("input", {
      ...fieldProps
    }), errorMessage && /* @__PURE__ */ React.createElement("p", {
      className: "error-message"
    }, errorMessage));
  }), /* @__PURE__ */ React.createElement("button", {
    className: buttonClass,
    type: "submit",
    disabled: isSending
  }, submitText));
}
export default Form;
