import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import axios from "../../../_snowpack/pkg/axios.js";
import ParentItem from "./parent-items/index.js";
import Spinner from "../spinner/index.js";
import ConfirmResolve from "../unresolved-products/resolve-modal.js";
import "./packing.css";
const Packing = ({match, item, setItem, openModal, setOpenModal}) => {
  const [childInfo, setChildInfo] = useState({isResolved: false});
  const [parents, setParents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const childId = match.params.childId;
  const history = useHistory();
  const thumbnail = childInfo.imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${childInfo.imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  useEffect(() => {
    setIsLoading(true);
    axios.get(`/api/v1/child-inventory/child-info/${childId}`).then(({data}) => {
      setChildInfo(data);
      setItem(data);
      setIsLoading(false);
    });
    axios.get(`/api/v1/child-inventory/parent/${childId}?populateChild=1`).then(({data}) => {
      setParents(data);
    });
  }, []);
  useEffect(() => {
    const fetchData = () => {
      axios.get(`/api/v1/child-inventory/child-info/${childId}`).then(({data}) => {
        setChildInfo(data);
        setItem(data);
        setIsLoading(false);
      });
      axios.get(`/api/v1/child-inventory/parent/${childId}?populateChild=1`).then(({data}) => {
        setParents(data);
      });
    };
    window.addEventListener("focus", fetchData);
    return () => window.removeEventListener("focus", fetchData);
  }, []);
  useEffect(() => {
    const {isResolved} = childInfo;
    if (isResolved) {
      history.push("/cart");
    }
  }, [childInfo]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "packing"
  }, isLoading && /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true,
    type: "overlay"
  }), /* @__PURE__ */ React.createElement(ConfirmResolve, {
    open: openModal,
    setOpen: setOpenModal,
    item,
    setItem,
    setRows,
    setIsLoading
  }), /* @__PURE__ */ React.createElement("div", {
    className: "child-product"
  }, /* @__PURE__ */ React.createElement("img", {
    src: thumbnail,
    alt: childInfo.title
  }), /* @__PURE__ */ React.createElement("div", {
    className: "child-product-info"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "title"
  }, childInfo.title), /* @__PURE__ */ React.createElement("div", {
    className: "child-product-asin-sku"
  }, /* @__PURE__ */ React.createElement("span", null, childInfo.asin), /* @__PURE__ */ React.createElement("span", null, childInfo.sellerSku)))), /* @__PURE__ */ React.createElement("div", {
    className: "child-inventory-info"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "prop-group"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "local inventory:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, childInfo.localInventory)), /* @__PURE__ */ React.createElement("div", {
    className: "prop-group"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "buy qty:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, childInfo.decidedBuyQuantity)), /* @__PURE__ */ React.createElement("div", {
    className: "prop-group"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Sellable:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, "Yes"))), /* @__PURE__ */ React.createElement("div", {
    className: "parent-items"
  }, parents && parents.length > 0 && parents.map((parent, index) => /* @__PURE__ */ React.createElement(ParentItem, {
    parent,
    key: index,
    setItem,
    setIsLoading,
    childInfo,
    setChildInfo,
    childId,
    setParents
  }))));
};
export default Packing;
