import React, {useState} from "../../../_snowpack/pkg/react.js";
import {useLocation, useRouteMatch, Link} from "../../../_snowpack/pkg/react-router-dom.js";
import {ChevronLeft, ExitToApp, HistoryOutlined, Settings, PlaylistAddCheck} from "../../../_snowpack/pkg/@material-ui/icons.js";
import {Avatar, Backdrop, Paper, Tooltip} from "../../../_snowpack/pkg/@material-ui/core.js";
import "./header.css";
const Header = ({user, handleLogout, isLongPress, setIsLongPress, setSelectedDelete, setOpenModal: setOpenResolveModal}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {pathname} = useLocation();
  const {path} = (useRouteMatch("/shopping-list/:fnsku") || useRouteMatch("/shopped-list/:fnsku") || useRouteMatch("/cart/sent")) ?? {};
  const {path: pathInventory} = useRouteMatch("/inventory/:productId") ?? {};
  const pathToTitleMap = {
    "/dashboard": "Dashboard",
    "/shopping-list": "Shopping List",
    "/shopped-list": "Shopped List",
    "/settings": "Settings",
    "/cart": "Cart",
    "/inventory": "Inventory"
  };
  const title = path ? path == "/cart/sent" ? "Cart" : "Bought Qty" : pathToTitleMap[pathname];
  const isOnBoughtQtyPage = path !== void 0;
  const toggleModal = (event) => {
    if (event.target)
      setIsModalOpen((isOpen) => !isOpen);
  };
  const handleCancelDelete = (e) => {
    e.preventDefault();
    setIsLongPress(false);
    setSelectedDelete(null);
  };
  const dateSixMonthsAgo = new Date(Date.now());
  dateSixMonthsAgo.setMonth(dateSixMonthsAgo.getMonth() - 6);
  const dateNow = new Date(Date.now()).toLocaleDateString();
  return /* @__PURE__ */ React.createElement("header", {
    className: "header"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "header-logo",
    src: "/assets/images/replen-dashboard-logo-dark-trimmed.svg"
  }), isOnBoughtQtyPage || !pathInventory && /* @__PURE__ */ React.createElement("div", {
    className: "icons-container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "shopped-list-container",
    style: {backgroundColor: pathname === "/buy-list-group" ? "#F93402" : "unset"}
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/buy-list-group",
    className: "shopped-list-button",
    style: {color: pathname === "/buy-list-group" ? "white" : "unset"}
  }, /* @__PURE__ */ React.createElement(Tooltip, {
    title: "Buy Lists",
    arrow: true
  }, /* @__PURE__ */ React.createElement(PlaylistAddCheck, {
    className: "icon-buy-list"
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "shopped-list-container",
    style: {backgroundColor: pathname === "/shopped-list" ? "#F93402" : "unset"}
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/shopped-list",
    className: "shopped-list-button",
    style: {color: pathname === "/shopped-list" ? "white" : "unset"}
  }, /* @__PURE__ */ React.createElement(Tooltip, {
    title: "Shopped List",
    arrow: true
  }, /* @__PURE__ */ React.createElement(HistoryOutlined, {
    className: "icon-history"
  })))), /* @__PURE__ */ React.createElement(Avatar, {
    className: "header-avatar",
    onClick: toggleModal,
    alt: user?.name
  })), /* @__PURE__ */ React.createElement("div", {
    className: "title-container"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "page-title",
    style: {display: title === "Bought Qty" && "block", textAlign: title === "Bought Qty" || title === "Cart" ? "right" : "unset", width: "100%"}
  }, title), isOnBoughtQtyPage && /* @__PURE__ */ React.createElement("p", {
    className: "title-subtext"
  }, "Transactions between ", dateSixMonthsAgo.toLocaleDateString(), " - ", dateNow)), isOnBoughtQtyPage && (isLongPress ? /* @__PURE__ */ React.createElement(Link, {
    to: path.replace("/:fnsku", ""),
    onClick: handleCancelDelete,
    className: "back-button",
    style: {paddingLeft: "20px"}
  }, "Cancel") : /* @__PURE__ */ React.createElement(Link, {
    to: title == "Bought Qty" ? path.replace("/:fnsku", "") : path.replace("/sent", ""),
    className: "back-button"
  }, /* @__PURE__ */ React.createElement(ChevronLeft, {
    fontSize: "large"
  }), " Back")), pathInventory && /* @__PURE__ */ React.createElement(Link, {
    to: pathInventory.replace("/:productId", ""),
    className: "back-button"
  }, /* @__PURE__ */ React.createElement(ChevronLeft, {
    fontSize: "large"
  }), " Back"), /* @__PURE__ */ React.createElement(Backdrop, {
    className: "modal",
    open: isModalOpen,
    onClick: toggleModal
  }, /* @__PURE__ */ React.createElement(Paper, {
    variant: "outlined",
    className: "modal-menu-container"
  }, /* @__PURE__ */ React.createElement(Avatar, {
    className: "modal-avatar",
    alt: user?.name
  }), /* @__PURE__ */ React.createElement("div", null, user?.name), /* @__PURE__ */ React.createElement(Link, {
    to: "/settings"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "settings-button"
  }, /* @__PURE__ */ React.createElement(Settings, null), " Settings")), /* @__PURE__ */ React.createElement("button", {
    className: "logout-button",
    onClick: handleLogout
  }, /* @__PURE__ */ React.createElement(ExitToApp, null), " Logout"))));
};
export default Header;
