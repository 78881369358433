import React, {useState, useEffect, useMemo} from "../../../_snowpack/pkg/react.js";
import {Route, useRouteMatch, useHistory} from "../../../_snowpack/pkg/react-router-dom.js";
import {useQuery} from "../../../_snowpack/pkg/react-query.js";
import axios from "../../../_snowpack/pkg/axios.js";
import ImportExportIcon from "../../../_snowpack/pkg/@material-ui/icons/ImportExport.js";
import {red} from "../../../_snowpack/pkg/@material-ui/core/colors.js";
import Toast from "../toast/index.js";
import Spinner from "../spinner/index.js";
import BoughtQty from "../bought-qty/index.js";
import ShoppingListItem from "../shopping-list-item/index.js";
import "./shopping-list.css";
const ShoppingList = ({isLongPress, setIsLongPress, setDeleteFunction, selectedDelete, setSelectedDelete, setNewCartItems, user}) => {
  const [search, setSearch] = useState("");
  const [supplier, setSupplier] = useState("N/A");
  const [storeSection, setStoreSection] = useState("");
  const [sorter, setSorter] = useState("A to Z");
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isRefetchingEnabled, setIsRefetchingEnabled] = useState(true);
  const {data, isFetchedAfterMount} = useQuery("shoppingList", fetchShoppingList, {
    enabled: isRefetchingEnabled,
    placeholderData: {products: []},
    refetchOnReconnect: false
  });
  const storeSections = useMemo(() => [
    ...new Set(data.products.filter(({storeSection: storeSection2}) => storeSection2).map(({storeSection: storeSection2}) => storeSection2))
  ], [data.products]);
  const suppliers = useMemo(() => [
    ...new Set(data.products.filter(({supplier: supplier2}) => supplier2 && supplier2 !== "N/A").map(({supplier: supplier2}) => supplier2))
  ], [data.products]);
  const history = useHistory();
  const match = useRouteMatch("/shopping-list/:sellerSku/:ext") || useRouteMatch("/shopping-list/:sellerSku");
  async function fetchShoppingList() {
    const response = await axios.get("/api/v1/inventory/shopping-list");
    return response.data;
  }
  function listFilter(product) {
    const keysToFilter = ["fnsku", "sellerSku", "title", "asin"];
    const lowerCaseSearchTerm = search.toLowerCase();
    const isInSearchResult = keysToFilter.some((key) => {
      const lowerCaseKeyValue = product[key]?.toLowerCase();
      return lowerCaseKeyValue?.includes(lowerCaseSearchTerm);
    });
    const matchesStoreSection = storeSection ? product?.storeSection === storeSection : true;
    const matchesSupplier = supplier === "N/A" ? true : product?.supplier === supplier;
    return isInSearchResult && matchesStoreSection && matchesSupplier;
  }
  const handleSort = () => {
    let productsList = data.products;
    if (sorter === "Z to A") {
      productsList.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else {
      productsList.sort((a, b) => {
        if (b.title < a.title) {
          return -1;
        }
        if (b.title > a.title) {
          return 1;
        }
        return 0;
      });
    }
    setIsRefetchingEnabled(false);
    setSorter(sorter === "A to Z" ? "Z to A" : "A to Z");
  };
  function openToastWithMessage(message) {
    setIsOpen(true);
    setToastMessage(message);
  }
  useEffect(() => {
    if (data.products.length > 0 && match?.params.sellerSku) {
      const product = data.products.find((product2) => match.params.ext ? product2.sellerSku === `${match.params.sellerSku}/${match.params.ext}` || product2.sellerSku === `#${match.params.sellerSku}/${match.params.ext}` : product2.sellerSku === match.params.sellerSku || product2.sellerSku === `#${match.params.sellerSku}`);
      setSelected(product ?? null);
      if (!product) {
        const error = `Product with seller SKU of "${match.params.sellerSku}" is not found in the shopping list.`;
        history.push("/shopping-list");
        openToastWithMessage(error);
      }
    }
  }, [data.products]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, selected !== null ? /* @__PURE__ */ React.createElement(Route, {
    path: `/shopping-list/:sellerSku`,
    render: (p) => /* @__PURE__ */ React.createElement(BoughtQty, {
      ...p,
      selected,
      setSelected,
      isRefetchingEnabled,
      setIsRefetchingEnabled,
      isLongPress,
      setIsLongPress,
      setDeleteFunction,
      selectedDelete,
      setSelectedDelete,
      list: data.products,
      setNewCartItems,
      user
    })
  }) : /* @__PURE__ */ React.createElement("div", {
    className: "shopping-list-page"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "controls"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "search",
    className: "search",
    onInput: (e) => setSearch(e.target.value),
    value: search,
    placeholder: "Search for Title, ASIN, FNSKU, or Seller SKU"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "filters"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "filter-field"
  }, /* @__PURE__ */ React.createElement("label", null, "Supplier"), /* @__PURE__ */ React.createElement("select", {
    value: supplier,
    onChange: (e) => setSupplier(e.target.value)
  }, /* @__PURE__ */ React.createElement("option", {
    value: "N/A"
  }, "(All Suppliers)"), suppliers.sort().map((supplier2) => /* @__PURE__ */ React.createElement("option", {
    value: supplier2,
    key: supplier2
  }, supplier2)))), /* @__PURE__ */ React.createElement("div", {
    className: "filter-field"
  }, /* @__PURE__ */ React.createElement("label", null, "Store Section"), /* @__PURE__ */ React.createElement("select", {
    value: storeSection,
    onChange: (e) => setStoreSection(e.target.value)
  }, /* @__PURE__ */ React.createElement("option", {
    value: ""
  }, "(All Store Sections)"), storeSections.sort().map((storeSection2) => /* @__PURE__ */ React.createElement("option", {
    value: storeSection2,
    key: storeSection2
  }, storeSection2))))), /* @__PURE__ */ React.createElement("div", {
    className: "sort"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "sort-by"
  }, "Sort by: ", /* @__PURE__ */ React.createElement("span", {
    onClick: handleSort
  }, sorter, /* @__PURE__ */ React.createElement(ImportExportIcon, {
    className: "sort-icon",
    style: {color: red[500]},
    fontSize: "small"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "sort-result"
  }, "Result: ", /* @__PURE__ */ React.createElement("span", null, data.products.filter(listFilter).length)))), /* @__PURE__ */ React.createElement("div", {
    className: "shopping-list"
  }, isFetchedAfterMount ? data.products.length ? data.products.filter(listFilter).map((product, i) => /* @__PURE__ */ React.createElement(ShoppingListItem, {
    product,
    setSelected,
    key: i,
    linkTo: "shopping-list"
  })) : /* @__PURE__ */ React.createElement("div", {
    className: "shopping-list-empty"
  }, /* @__PURE__ */ React.createElement("p", null, "You have no products in your shopping list."), /* @__PURE__ */ React.createElement("p", null, "Once there's a product with a buy quantity,", /* @__PURE__ */ React.createElement("br", null), "it will show up here.")) : /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true
  }))), /* @__PURE__ */ React.createElement(Toast, {
    isOpen,
    handleClose: () => setIsOpen(false),
    type: "error",
    anchorOrigin: {vertical: "top", horizontal: "center"}
  }, toastMessage));
};
export default ShoppingList;
