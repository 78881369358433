import React from "../../../_snowpack/pkg/react.js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Radio,
  Button
} from "../../../_snowpack/pkg/@material-ui/core.js";
import {useState} from "../../../_snowpack/pkg/react.js";
import "./buy-list-merge-selection.css";
const BuyListMergeSelection = ({showBuyListMerge, setShowBuyListMerge, buyListArray, handlePack}) => {
  const [selectedValue, setSelectedValue] = useState();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleCancel = () => {
    setShowBuyListMerge(false);
  };
  const handleOk = () => {
    if (selectedValue) {
      handlePack(selectedValue);
      setShowBuyListMerge(false);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Dialog, {
    "aria-labelledby": "form-dialog-title",
    open: showBuyListMerge
  }, /* @__PURE__ */ React.createElement(DialogTitle, null, "Buy List Selection"), /* @__PURE__ */ React.createElement(DialogContent, null, /* @__PURE__ */ React.createElement(DialogContentText, null, "Child items belongs to more than one(1) buy list, ", /* @__PURE__ */ React.createElement("br", null), " Please select a buy list for the item to pack."), /* @__PURE__ */ React.createElement(List, {
    component: "nav",
    "aria-labelledby": "main-buylist"
  }, buyListArray.map((value) => {
    const arrValue = value.split("||");
    return /* @__PURE__ */ React.createElement(ListItem, {
      key: arrValue[0]
    }, /* @__PURE__ */ React.createElement(ListItemText, {
      primary: `${arrValue[1]}`
    }), /* @__PURE__ */ React.createElement(ListItemSecondaryAction, null, /* @__PURE__ */ React.createElement(Radio, {
      edge: "end",
      value: arrValue[0],
      checked: arrValue[0] == selectedValue,
      onChange: handleChange
    })));
  }))), /* @__PURE__ */ React.createElement(DialogActions, {
    className: "action-section"
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "action-btn-secondary",
    size: "small",
    onClick: handleCancel
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button, {
    className: "action-btn-primary",
    size: "small",
    onClick: handleOk
  }, "Ok"))));
};
export default BuyListMergeSelection;
