import React, {useState, useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import ImportExportIcon from "../../../_snowpack/pkg/@material-ui/icons/ImportExport.js";
import {red} from "../../../_snowpack/pkg/@material-ui/core/colors.js";
import axios from "../../../_snowpack/pkg/axios.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import {List, AutoSizer, CellMeasurer, CellMeasurerCache} from "../../../_snowpack/pkg/react-virtualized.js";
import InventoryItem from "./inventory-list-item/index.js";
import Spinner from "../spinner/index.js";
import "./inventory.css";
const Inventory = ({skip, inventory, setInventory}) => {
  const cache = useRef(new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 150
  }));
  const savedSearch = typeof window !== "undefined" ? localStorage.getItem("inventorySearch") : null;
  const [search, setSearch] = useState(savedSearch ? savedSearch : "");
  const [sorter, setSorter] = useState("A to Z");
  useEffect(() => {
    setInventory((old) => {
      return {products: old.products.sort((a, b) => {
        if (sorter === "A to Z") {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
        } else {
          if (b.title < a.title) {
            return -1;
          }
          if (b.title > a.title) {
            return 1;
          }
        }
      })};
    });
  }, [skip]);
  useEffect(() => {
    localStorage.setItem("inventorySearch", search);
  }, [search]);
  useEffect(() => {
    localStorage.setItem("selectedInventory", "");
  }, []);
  const handleSort = () => {
    let productsList = inventory.products;
    if (sorter === "Z to A") {
      productsList.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else {
      productsList.sort((a, b) => {
        if (b.title < a.title) {
          return -1;
        }
        if (b.title > a.title) {
          return 1;
        }
        return 0;
      });
    }
    setSorter((old) => old === "A to Z" ? "Z to A" : "A to Z");
  };
  const listFilter = (product) => {
    const keysToFilter = ["fnsku", "sellerSku", "title", "asin"];
    const lowerCaseSearchTerm = search.toLowerCase();
    const isInSearchResult = keysToFilter.some((key) => {
      const lowerCaseKeyValue = product[key]?.toLowerCase();
      return lowerCaseKeyValue?.includes(lowerCaseSearchTerm);
    });
    return isInSearchResult;
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "inventory"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "controls"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "search-container"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "search",
    className: "search",
    onInput: (e) => setSearch(e.target.value),
    value: search,
    placeholder: "Search for Title, ASIN, FNSKU, or Seller SKU"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "sort-container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "sort"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "sort-by"
  }, "Sort by: ", /* @__PURE__ */ React.createElement("span", {
    onClick: handleSort
  }, sorter, /* @__PURE__ */ React.createElement(ImportExportIcon, {
    className: "sort-icon",
    style: {color: red[500]},
    fontSize: "small"
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "sort-result"
  }, "Result: ", /* @__PURE__ */ React.createElement("span", null, inventory.products.filter(listFilter).length)))))), /* @__PURE__ */ React.createElement("div", {
    className: "inventory-items"
  }, inventory && inventory.products.filter(listFilter).length > 0 ? /* @__PURE__ */ React.createElement("div", {
    style: {width: "100%", height: "100%"}
  }, /* @__PURE__ */ React.createElement(AutoSizer, null, ({width, height}) => /* @__PURE__ */ React.createElement(List, {
    width,
    height,
    rowHeight: cache.current.rowHeight,
    rowCount: inventory.products.filter(listFilter).length,
    deferredMeasurementCache: cache.current,
    rowRenderer: ({key, index, style, parent}) => {
      const inventoryItem = inventory.products.filter(listFilter)[index];
      const rowStyle = {...style, backgroundColor: index % 2 ? "unset" : "white"};
      return /* @__PURE__ */ React.createElement(CellMeasurer, {
        key,
        cache: cache.current,
        parent,
        columnIndex: 0,
        rowIndex: index
      }, /* @__PURE__ */ React.createElement(Link, {
        to: `/inventory/${inventoryItem._id}`,
        style: rowStyle,
        className: `inventory-item-link`
      }, /* @__PURE__ */ React.createElement(InventoryItem, {
        inventoryItem,
        style
      })));
    }
  }))) : /* @__PURE__ */ React.createElement(Spinner, {
    isCentered: true
  })));
};
export default Inventory;
