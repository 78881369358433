import React, {useState, useEffect} from "../../../../_snowpack/pkg/react.js";
import {ArrowRight} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import ChildRow from "./child-row/index.js";
import BuyListMergeSelection from "../../buy-list-merge-selection/buy-list-merge-selection.js";
import axios from "../../../../_snowpack/pkg/axios.js";
import "./parent-item.css";
const ParentItem = ({parent, setParents, setItem, setIsLoading, setChildInfo, childId}) => {
  const [expand, setExpand] = useState(false);
  const {title, asin, sellerSku, imageUrl, localInventory, decidedBuyQuantity, childItems, _id} = parent;
  const [packQty, setPackQty] = useState(1);
  const childIds = childItems.map((child) => child.child._id);
  const [buyLists, setBuyLists] = useState([]);
  const [showBuyListMerge, setShowBuyListMerge] = useState(false);
  const handleItemBuyList = async () => {
    const resp = await axios.get(`/api/v1/cart-journal/${[...childIds]}`);
    if (resp.status === 200) {
      const uniqueBuyists = [...new Set(resp.data.map((list) => `${list.buyListId._id}||${list.buyListId.name}`))];
      setBuyLists(uniqueBuyists);
    }
  };
  useEffect(() => {
    handleItemBuyList();
  }, []);
  const thumbnail = imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  const handlePacking = (buyListId) => {
    setIsLoading(true);
    axios.post(`/api/v1/child-inventory/pack/${sellerSku}`, {qty: packQty, buyListId}).then((res) => {
      axios.get(`/api/v1/child-inventory/child-info/${childId}`).then(({data}) => {
        setChildInfo(data);
        setItem(data);
        axios.get(`/api/v1/child-inventory/parent/${childId}?populateChild=1`).then(({data: data2}) => {
          setParents(data2);
          setIsLoading(false);
        });
      });
    });
  };
  const handleBuyListMerge = (event) => {
    event.preventDefault();
    if (buyLists.length > 1) {
      setShowBuyListMerge(true);
    } else if (buyLists.length === 1) {
      const arrBuyList = buyLists[0].split("||");
      handlePacking(arrBuyList[0]);
    } else {
      handlePacking(void 0);
    }
  };
  const isEnoughForPacking = childItems.every(({child, quantity}) => {
    return child.localInventory >= quantity * packQty;
  });
  const canPack = packQty > 0 && isEnoughForPacking;
  return /* @__PURE__ */ React.createElement("div", {
    className: "parent-item"
  }, /* @__PURE__ */ React.createElement(BuyListMergeSelection, {
    showBuyListMerge,
    setShowBuyListMerge,
    buyListArray: buyLists,
    handlePack: handlePacking
  }), /* @__PURE__ */ React.createElement("div", {
    className: "parent-row"
  }, /* @__PURE__ */ React.createElement("img", {
    src: thumbnail,
    alt: `${title} - parent`
  }), /* @__PURE__ */ React.createElement("div", {
    className: "parent-info"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "title"
  }, title), /* @__PURE__ */ React.createElement("div", {
    className: "parent-asin-sku"
  }, /* @__PURE__ */ React.createElement("p", null, asin), /* @__PURE__ */ React.createElement("p", null, sellerSku))), /* @__PURE__ */ React.createElement(ArrowRight, {
    className: expand ? "expand-icon expand" : "expand-icon",
    onClick: () => setExpand((old) => !old)
  }), /* @__PURE__ */ React.createElement("div", {
    className: "actions-section"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "info action-pack"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "info-container"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Local inv.:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, localInventory || 0)), /* @__PURE__ */ React.createElement("div", {
    className: "info-container"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Buy Qty:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, decidedBuyQuantity || 0))), /* @__PURE__ */ React.createElement("div", {
    className: "action action-pack"
  }, /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleBuyListMerge
  }, /* @__PURE__ */ React.createElement("input", {
    type: "number",
    min: "0",
    step: "1",
    value: packQty,
    onChange: (e) => setPackQty(e.target.value),
    onFocus: (e) => e.target.select()
  }), /* @__PURE__ */ React.createElement("button", {
    disabled: !canPack,
    className: !canPack ? "disabled" : void 0
  }, "Pack")))), /* @__PURE__ */ React.createElement("div", {
    className: expand ? "children-section expand-children" : "children-section"
  }, childItems && childItems.length > 0 && childItems.map((child, index) => /* @__PURE__ */ React.createElement(ChildRow, {
    child,
    key: index,
    packQty
  })))));
};
export default ParentItem;
