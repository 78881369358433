import React, {useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import {Accordion, AccordionSummary, AccordionDetails} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ArrowDropDown} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import axios from "../../../../../_snowpack/pkg/axios.js";
import "./accordionGrid.css";
import {chooseDefaultCalculationPrice, GetProfitEstimations} from "../../../../utilities/functions.js";
const Cost = ({expanded, setExpanded, product, setProduct, fetching, productCopy, setOpenToast, setToastMsg, setInventory, inventory}) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {buyBoxPrice, costPrice, fee, shipBy, packageDimensions, shippingRate, profit, roiPercentage, profitPercentage, sellerSku, _id: productId, profitCalculatedBy, listPrice, lowestOfferPrice, lowestFBAOfferPrice, weight, prepCenterFees, defaultPrice, fbmFee, fulfilmentType} = product;
  const dateNow = new Date();
  const monthlyStorage = [9, 10, 11].includes(dateNow.getMonth()) ? fee?.monthlyStorageFeeOct : fee?.monthlyStorageFeeJan;
  const [cost, setCost] = useState(0);
  const firstRowLabel = (profitCalculatedBy2) => {
    if (profitCalculatedBy2 == "buyBoxPrice" && !buyBoxPrice && lowestFBAOfferPrice || profitCalculatedBy2 == "lowestOfferPrice" && !lowestOfferPrice && lowestFBAOfferPrice) {
      return "Lowest FBA Offer Price";
    } else if (profitCalculatedBy2 == "buyBoxPrice" && !buyBoxPrice && listPrice || profitCalculatedBy2 == "lowestOfferPrice" && !lowestOfferPrice && listPrice || profitCalculatedBy2 == "lowestFBAOfferPrice" && !lowestFBAOfferPrice && !buyBoxPrice && listPrice) {
      return "Sale Price";
    } else if (profitCalculatedBy2 == "lowestFBAOfferPrice" && !lowestFBAOfferPrice && buyBoxPrice) {
      return "Buybox Price";
    } else if (!profitCalculatedBy2) {
      return "Sale Price";
    } else if (profitCalculatedBy2 == "listPrice") {
      return "Sale Price";
    } else if (profitCalculatedBy2 == "buyBoxPrice") {
      return "Buy Box Price";
    } else if (profitCalculatedBy2 == "lowestFBAOfferPrice") {
      return "Lowest FBA Offer Price";
    } else if (profitCalculatedBy2 == "lowestOfferPrice") {
      return "Lowest Offer Price";
    } else
      return "";
  };
  const expandedRows = [
    {label: firstRowLabel(profitCalculatedBy), value: `$${defaultPrice ?? 0}`},
    {label: "cost", value: `$${costPrice ?? 0}`},
    {label: "referral", value: `$${(defaultPrice * (fee ? fee.feePercentage / 100 : 0)).toFixed(2) ?? 0}`},
    {label: "FBA", value: fee && fee.otherFees ? fee.otherFees : 0},
    ...fulfilmentType != "Merchant" ? [{label: "storage", value: `$${monthlyStorage ?? 0}`}] : [],
    ...fulfilmentType != "Merchant" ? [{label: "inbound(per item)", value: `$${(shipBy ? shipBy === "byItem" ? shippingRate : packageDimensions ? packageDimensions?.weight * shippingRate : weight ? weight * shippingRate : 0 : 0).toFixed(2)}`}] : [],
    ...prepCenterFees ? [{label: "Prep. Center Fees", value: prepCenterFees >= 0 ? `$${prepCenterFees ?? 0}` : `-$${(prepCenterFees ?? 0) * -1}`}] : [],
    ...fbmFee && fulfilmentType == "Merchant" ? [{label: "FBM Fee", value: `$${fbmFee}`}] : [],
    {label: "profit", value: profit >= 0 ? `$${profit ?? 0}` : `-$${(profit ?? 0) * -1}`},
    {label: "roi", value: roiPercentage ? `${roiPercentage}%` : "No Cost"},
    {label: "margin", value: `${profitPercentage}%`}
  ];
  useEffect(() => {
    if (costPrice !== void 0 && costPrice !== null) {
      setCost(costPrice);
    }
  }, [fetching]);
  const handleChangeCost = (e) => {
    e.preventDefault();
    if (product.costPrice != cost) {
      setCostPrice(sellerSku, cost);
    }
  };
  const setCostPrice = async (sku, newCost) => {
    const filteredInventory = inventory.products.filter((item) => item._id !== product._id);
    const {data} = await axios.post("/api/v1/inventory/set-cost-price", null, {params: {sellerSku: sku, costPrice: newCost === "" ? 0 : newCost}});
    const {profitCalculatedBy: profitCalculatedBy2, listPrice: listPrice2, buyBoxPrice: buyBoxPrice2, lowestOfferPrice: lowestOfferPrice2, lowestFBAOfferPrice: lowestFBAOfferPrice2, costPrice: costPrice2, fee: fee2, fulfilmentType: fulfilmentType2, shipBy: shipBy2, shippingRate: shippingRate2, prepCenterFees: prepCenterFees2, weight: weight2, enrolledInSnL, fbmFee: fbmFee2} = data;
    const defaultPrice2 = chooseDefaultCalculationPrice({profitCalculatedBy: profitCalculatedBy2, listPrice: listPrice2, buyBoxPrice: buyBoxPrice2, lowestOfferPrice: lowestOfferPrice2, lowestFBAOfferPrice: lowestFBAOfferPrice2});
    const updated_data = GetProfitEstimations({costPrice: costPrice2, defaultPrice: defaultPrice2, fee: fee2, fulfilmentType: fulfilmentType2, shipBy: shipBy2, shippingRate: shippingRate2, prepCenterFees: prepCenterFees2, weight: weight2, fbmFee: fbmFee2, buyBoxPrice: buyBoxPrice2});
    setInventory({products: [...filteredInventory, {...product, ...data, ...updated_data, defaultPrice: defaultPrice2}]});
    setProduct((old) => ({...old, ...data, ...updated_data, defaultPrice: defaultPrice2}));
    setToastMsg("You have successfully set cost.");
    setOpenToast(true);
  };
  const handleOnChangeCost = (e) => {
    const checkIfContainDecimal = e.target.value.toString().split(".");
    if (checkIfContainDecimal.length === 2) {
      checkIfContainDecimal[1] = checkIfContainDecimal[1].substring(0, 2);
      const filteredCost = checkIfContainDecimal.join(".");
      setCost(filteredCost);
    } else {
      setCost(e.target.value);
    }
  };
  return /* @__PURE__ */ React.createElement(Accordion, {
    expanded: expanded === "panel5",
    onChange: handleChange("panel5"),
    style: {borderBottom: "1px solid var(--replen-light-bg)"}
  }, /* @__PURE__ */ React.createElement(AccordionSummary, {
    expandIcon: /* @__PURE__ */ React.createElement(ArrowDropDown, null),
    "aria-controls": "panel-content",
    id: "panel-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-summary"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Cost"), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleChangeCost,
    className: "left"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "number",
    step: "any",
    value: cost,
    onChange: handleOnChangeCost,
    className: "value input left",
    onClick: (e) => e.target.select(),
    onBlur: handleChangeCost
  }))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", {
    className: "label",
    style: {textAlign: "center"}
  }, "ROI"), /* @__PURE__ */ React.createElement("p", {
    className: "value",
    style: {textAlign: "center"}
  }, roiPercentage ? `${roiPercentage}%` : "No Cost")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", {
    className: "label",
    style: {textAlign: "right"}
  }, "Profit"), /* @__PURE__ */ React.createElement("p", {
    className: "value",
    style: {textAlign: "right"}
  }, profit >= 0 ? `$${profit ?? 0}` : `-$${(profit ?? 0) * -1}`)))), /* @__PURE__ */ React.createElement(AccordionDetails, {
    id: "panel-body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body"
  }, expandedRows.map((row, index) => /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body-row",
    key: index
  }, /* @__PURE__ */ React.createElement("p", {
    className: row.label === "profit" ? "label highlight" : "label"
  }, row.label, ":"), /* @__PURE__ */ React.createElement("p", {
    className: row.label === "profit" ? "value highlight" : "value"
  }, row.value))))));
};
export default Cost;
