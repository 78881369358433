import React, {useState} from "../../../_snowpack/pkg/react.js";
import axios from "../../../_snowpack/pkg/axios.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import Card from "../card/index.js";
import Form from "../form/index.js";
import Toast from "../toast/index.js";
import {Button} from "../../../_snowpack/pkg/@material-ui/core.js";
const Login = ({handleLoginSuccess}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepLogin, setKeepLogin] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const fields = [
    {
      label: "Email",
      fieldProps: {
        type: "email",
        value: email,
        placeholder: "Email",
        name: "email",
        onInput: (e) => setEmail(e.target.value)
      }
    },
    {
      label: "Password",
      fieldProps: {
        type: "password",
        placeholder: "Password",
        value: password,
        name: "password",
        onInput: (e) => setPassword(e.target.value)
      }
    },
    {
      label: "Keep me logged in?",
      fieldProps: {
        type: "checkbox",
        checked: keepLogin,
        onChange: () => setKeepLogin(!keepLogin)
      },
      isCentered: true,
      isMuted: true,
      isSmall: true
    }
  ];
  const openToastWithMessage = (message) => {
    setIsOpen(true);
    setToastMessage(message);
  };
  const validateUserIfHasAccessToShoppingList = (user) => {
    let hasAccess = false;
    if (user.permission && user.permission.role && user.permission.role.length > 0) {
      if (user.status === "Child User") {
        hasAccess = user.permission.role.some((role) => role === "Can View PWA Shopping List");
      } else {
        hasAccess = user.permission.role.some((role) => role === "Can View PWA Shopping List" || role === "Default");
      }
    }
    return hasAccess;
  };
  const sendLoginRequest = async () => {
    try {
      const response = await axios.post("/api/v1/login", {email, password});
      const {token, user} = await response.data;
      if (user?.admin) {
        setIsSending(false);
        return openToastWithMessage("You cannot use this app with admin permission");
      }
      if (!validateUserIfHasAccessToShoppingList(user)) {
        setIsSending(false);
        return openToastWithMessage("You do not have permission to use this app.");
      }
      handleLoginSuccess(token, user);
    } catch (error) {
      let errorMessage;
      switch (error?.response?.status) {
        case 400:
          errorMessage = "Invalid credentials, please check your email or password";
          break;
        case 500:
          errorMessage = "Server error, something went wrong on our end";
          break;
        default:
          errorMessage = "Network error, please check your internet connection";
      }
      openToastWithMessage(errorMessage);
      setIsSending(false);
    }
  };
  const onLogin = (event) => {
    event.preventDefault();
    setIsSending(true);
    sendLoginRequest();
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Card, null, /* @__PURE__ */ React.createElement("div", {
    className: "title"
  }, "Sign In"), /* @__PURE__ */ React.createElement("span", {
    className: "small muted"
  }, "Don't have an account yet?", /* @__PURE__ */ React.createElement("br", null), " ", /* @__PURE__ */ React.createElement("a", {
    className: "link-orange font-medium"
  }, "Create one")), /* @__PURE__ */ React.createElement(Form, {
    fields,
    submitText: "Login",
    onSubmit: onLogin,
    isSending
  }), /* @__PURE__ */ React.createElement(Link, {
    to: "/auth/forgot-password",
    className: "link-orange font-medium"
  }, "Forgot your password?")), /* @__PURE__ */ React.createElement(Toast, {
    isOpen,
    handleClose: () => setIsOpen(false),
    type: "error"
  }, toastMessage));
};
export default Login;
