import React, {useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import "./row-edit.css";
import axios from "../../../../../_snowpack/pkg/axios.js";
const StoreSection = ({label = "", value = "", fetching, product, inventory, setInventory, setToastMsg, setOpenToast}) => {
  const [supplier, setSupplier] = useState(value);
  useEffect(() => {
    if (product.supplier !== void 0 && product.supplier !== null) {
      setSupplier(product.supplier);
    }
  }, [fetching]);
  const onChange = (e) => {
    setSupplier(e.target.value);
  };
  const handleChangeSupllier = async (sellerSku, supplier2) => {
    const newInventory = inventory.products.filter((p) => p._id !== product._id);
    const {data} = await axios.post("api/v1/inventory/set-supplier", null, {params: {sellerSku, supplier: supplier2}});
    setInventory({products: [...newInventory, {...product, ...data}]});
    setToastMsg("You have successfully set store section.");
    setOpenToast(true);
  };
  const onBlur = (e) => {
    e.preventDefault();
    if (product.supplier != supplier) {
      handleChangeSupllier(product.sellerSku, supplier);
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "row-edit-container"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, label), /* @__PURE__ */ React.createElement("input", {
    className: "value input left",
    value: supplier,
    onChange,
    onClick: (e) => e.target.select(),
    onBlur
  }));
};
export default StoreSection;
