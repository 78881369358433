import React from "../../../../_snowpack/pkg/react.js";
import {Link} from "../../../../_snowpack/pkg/react-router-dom.js";
import {ChevronRight} from "../../../../_snowpack/pkg/@material-ui/icons.js";
import "./inventory-list-item.css";
const InventoryListItem = ({inventoryItem}) => {
  const {title, imageUrl, asin, costPrice, afnTotalQuantity, supplier, storeSection, isAlwaysBuyList, decidedBuyQuantity, sellerSku, localInventory, _id: productId} = inventoryItem;
  const thumbnail = imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  const shoppingListItemClass = `inventory-list-item`;
  return /* @__PURE__ */ React.createElement("div", {
    className: shoppingListItemClass,
    onClick: () => localStorage.setItem("selectedInventory", productId)
  }, /* @__PURE__ */ React.createElement("img", {
    className: "thumbnail",
    src: thumbnail
  }), /* @__PURE__ */ React.createElement("div", {
    className: "title"
  }, title), /* @__PURE__ */ React.createElement("div", {
    className: "props"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "ASIN"), /* @__PURE__ */ React.createElement("div", null, asin)), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "SKU"), /* @__PURE__ */ React.createElement("div", null, sellerSku)), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "QTY"), /* @__PURE__ */ React.createElement("div", null, afnTotalQuantity)), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Local Inv"), /* @__PURE__ */ React.createElement("div", null, localInventory ?? 0))));
};
export default InventoryListItem;
