import React from "../../../../../_snowpack/pkg/react.js";
import {Accordion, AccordionSummary, AccordionDetails} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ArrowDropDown} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import "./accordionFlex.css";
const BuyBox = ({expanded, setExpanded, product}) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {buyQuantity30Days, totalOrdersLast30Days, salesLast30Days, salesAmountLast30Days, totalPendingOrdersLast30Days, afnTotalQuantity, localInventory, buyBoxPrice, lowestOfferPrice, lowestFBAOfferPrice, noOfSellers, salesRank} = product;
  const expandedRows = [
    {label: "lowest offer price", value: `$${lowestOfferPrice ?? 0}`},
    {label: "lowest FBA offer price", value: `$${lowestFBAOfferPrice ?? 0}`},
    {label: "sale amount", value: `$${salesAmountLast30Days ?? 0}`},
    {label: "no. of sellers", value: noOfSellers ?? 0},
    {label: "sales rank", value: salesRank ?? 0}
  ];
  return /* @__PURE__ */ React.createElement(Accordion, {
    expanded: expanded === "panel6",
    onChange: handleChange("panel6"),
    style: {borderBottom: "1px solid var(--replen-light-bg)"}
  }, /* @__PURE__ */ React.createElement(AccordionSummary, {
    expandIcon: /* @__PURE__ */ React.createElement(ArrowDropDown, null),
    "aria-controls": "panel-content",
    id: "panel-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-summary"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Buy Box Price"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, "$", buyBoxPrice ?? 0))), /* @__PURE__ */ React.createElement(AccordionDetails, {
    id: "panel-body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body"
  }, expandedRows.map((row, index) => /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body-row",
    key: index
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, row.label, ":"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, row.value))))));
};
export default BuyBox;
