import axios from "../../../_snowpack/pkg/axios.js";
import React, {useEffect, useState} from "../../../_snowpack/pkg/react.js";
import BuyListGroupSelection from "../buy-list-selection/index.js";
import Spinner from "../spinner/index.js";
import "./default-buylist.css";
const DefaultBuylist = ({user, selectedItem, setSelectedItem}) => {
  const [showBuyList, setShowBuyList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const defBuylist = localStorage.getItem(`default-buylist-${user._id}`);
    if (defBuylist) {
      const jsonBuyList = JSON.parse(defBuylist);
      validateBuyList(jsonBuyList._id);
    }
  }, []);
  const validateBuyList = (buyListId) => {
    setIsLoading(true);
    axios.get(`/api/v1/buy-list-group/${buyListId}`).then((res) => {
      if (res.status === 200) {
        if (res?.data.isArchive === false) {
          setSelectedItem(res.data);
          setIsLoading(false);
        }
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };
  const handleBuyListClick = (event) => {
    event.preventDefault();
    setShowBuyList(true);
  };
  const onBuyList = () => {
    if (selectedItem) {
      localStorage.setItem(`default-buylist-${user?._id}`, JSON.stringify(selectedItem));
      setShowBuyList(false);
    }
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(BuyListGroupSelection, {
    showBuyList,
    setShowBuyList,
    selectedItem,
    setSelectedItem,
    onBuyList
  }), isLoading ? /* @__PURE__ */ React.createElement(Spinner, {
    size: 20
  }) : /* @__PURE__ */ React.createElement("button", {
    className: "btn-buy-list",
    onClick: handleBuyListClick
  }, selectedItem ? selectedItem.name : "<Choose Buy List>"));
};
export default DefaultBuylist;
