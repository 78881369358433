import React from "../../../_snowpack/pkg/react.js";
import {CircularProgress} from "../../../_snowpack/pkg/@material-ui/core.js";
import "./spinner.css";
const Spinner = ({size = 40, isCentered = false, type = "loading"}) => /* @__PURE__ */ React.createElement(React.Fragment, null, type === "overlay" ? /* @__PURE__ */ React.createElement("div", {
  className: "container",
  style: {zIndex: 99}
}, /* @__PURE__ */ React.createElement(CircularProgress, {
  size,
  className: `orange-spinner${isCentered ? " is-centered" : ""}`
})) : /* @__PURE__ */ React.createElement(CircularProgress, {
  size,
  className: `orange-spinner${isCentered ? " is-centered" : ""}`
}));
export default Spinner;
