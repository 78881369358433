import React from "../../../_snowpack/pkg/react.js";
import {CloudOff} from "../../../_snowpack/pkg/@material-ui/icons.js";
import "./offline-page.css";
const OfflinePage = ({handleReconnect}) => {
  return /* @__PURE__ */ React.createElement("main", {
    className: "offline-page"
  }, /* @__PURE__ */ React.createElement(CloudOff, null), /* @__PURE__ */ React.createElement("h1", null, "You are currently offline."), /* @__PURE__ */ React.createElement("p", null, "Please check your internet connection."), /* @__PURE__ */ React.createElement("button", {
    className: "reconnect",
    onClick: handleReconnect
  }, "Reconnect"));
};
export default OfflinePage;
