import React from "../../../../../_snowpack/pkg/react.js";
import {Accordion, AccordionDetails, AccordionSummary} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ArrowDropDown} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import "./accordionFlex.css";
const BuyLink = ({expanded, setExpanded, product}) => {
  const {purchaseLink} = product;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return /* @__PURE__ */ React.createElement(Accordion, {
    expanded: expanded === "panel7",
    onChange: handleChange("panel7"),
    style: {borderBottom: "1px solid var(--replen-light-bg)"}
  }, /* @__PURE__ */ React.createElement(AccordionSummary, {
    expandIcon: /* @__PURE__ */ React.createElement(ArrowDropDown, null),
    "aria-controls": "panel-content",
    id: "panel-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-summary"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Buy Link"), /* @__PURE__ */ React.createElement("p", {
    className: "value buy-link"
  }, purchaseLink && purchaseLink.length ? purchaseLink.length == 1 ? /* @__PURE__ */ React.createElement("a", {
    href: purchaseLink[0],
    target: "_blank"
  }, purchaseLink[0]) : `${purchaseLink.length} Links` : "N/A"))), /* @__PURE__ */ React.createElement(AccordionDetails, {
    id: "panel-body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body"
  }, purchaseLink ? purchaseLink.map((link, index) => /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body-row",
    key: index
  }, /* @__PURE__ */ React.createElement("a", {
    className: "link",
    href: link,
    target: "_blank"
  }, link))) : "N/A")));
};
export default BuyLink;
