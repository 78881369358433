import React from "../../../../../_snowpack/pkg/react.js";
import {Accordion, AccordionSummary, AccordionDetails} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ArrowDropDown} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import "./accordionFlex.css";
const SixtyDays = ({expanded, setExpanded, product}) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {buyQuantity60Days, totalOrdersLast60Days, salesLast60Days, salesAmountLast60Days, totalPendingOrdersLast60Days, afnTotalQuantity, localInventory, decidedBuyQuantity} = product;
  let adjusted60BuyQty = 0;
  if (!isNaN(buyQuantity60Days)) {
    adjusted60BuyQty = buyQuantity60Days;
    if (!isNaN(localInventory)) {
      adjusted60BuyQty = buyQuantity60Days - localInventory;
    }
    if (!isNaN(decidedBuyQuantity)) {
      adjusted60BuyQty = adjusted60BuyQty - decidedBuyQuantity;
    }
  }
  const expandedRows = [
    {label: "orders", value: totalOrdersLast60Days ?? 0},
    {label: "units", value: salesLast60Days ?? 0},
    {label: "sale amount", value: `$${salesAmountLast60Days ?? 0}`},
    {label: "pending orders", value: totalPendingOrdersLast60Days ?? 0},
    {label: "inventory qty", value: afnTotalQuantity ?? 0},
    {label: "local qty", value: localInventory ?? 0},
    {label: "recommended buy list adj", value: adjusted60BuyQty ?? 0}
  ];
  return /* @__PURE__ */ React.createElement(Accordion, {
    expanded: expanded === "panel3",
    onChange: handleChange("panel3"),
    style: {borderBottom: "1px solid var(--replen-light-bg)"}
  }, /* @__PURE__ */ React.createElement(AccordionSummary, {
    expandIcon: /* @__PURE__ */ React.createElement(ArrowDropDown, null),
    "aria-controls": "panel-content",
    id: "panel-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-summary"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "60 Days"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, adjusted60BuyQty ?? 0))), /* @__PURE__ */ React.createElement(AccordionDetails, {
    id: "panel-body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body"
  }, expandedRows.map((row, index) => /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body-row",
    key: index
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, row.label, ":"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, row.value))))));
};
export default SixtyDays;
