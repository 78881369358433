import React, {useState, useEffect} from "../../../../../_snowpack/pkg/react.js";
import {Accordion, AccordionSummary, AccordionDetails} from "../../../../../_snowpack/pkg/@material-ui/core.js";
import {ArrowDropDown} from "../../../../../_snowpack/pkg/@material-ui/icons.js";
import axios from "../../../../../_snowpack/pkg/axios.js";
import "./accordionGrid.css";
const Quantities = ({expanded, setExpanded, product, setProduct, fetching, productCopy, setOpenToast, setToastMsg, setInventory, inventory}) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {afnTotalQuantity, localInventory, decidedBuyQuantity, afnInboundWorkingQuantity, afnInboundShippedQuantity, afnInboundReceivingQuantity, afnReservedQuantity, afnResearchingQuantity, sellableQuantity, afnUnsellableQuantity, sellerSku, _id: productId, afnReservedQuantityCustOrder, afnReservedQuantityFcProc, afnReservedQuantityFcTrans} = product;
  const [buyQty, setBuyQty] = useState(0);
  const expandedRows = [
    {label: "inbound working", value: afnInboundWorkingQuantity ?? 0},
    {label: "inbound shipped", value: afnInboundShippedQuantity ?? 0},
    {label: "inbound received", value: afnInboundReceivingQuantity ?? 0},
    {label: "reserved", value: afnReservedQuantity ?? 0},
    {label: "customer order", value: afnReservedQuantityCustOrder ?? 0, sub: true},
    {label: "fc processing", value: afnReservedQuantityFcProc ?? 0, sub: true},
    {label: "fc transfers", value: afnReservedQuantityFcTrans ?? 0, sub: true},
    {label: "researching", value: afnResearchingQuantity ?? 0},
    {label: "sellable", value: sellableQuantity ?? 0},
    {label: "total", value: afnTotalQuantity ?? 0},
    {label: "unsellable", value: afnUnsellableQuantity ?? 0}
  ];
  useEffect(() => {
    if (decidedBuyQuantity !== void 0 && decidedBuyQuantity !== null) {
      setBuyQty(decidedBuyQuantity);
    }
  }, [fetching]);
  const handleChangeBuyQty = (e) => {
    e.preventDefault();
    if (product.decidedBuyQuantity != buyQty) {
      setDecideBuyQuantity(sellerSku, buyQty);
    }
  };
  const setDecideBuyQuantity = async (sku, decidedBuyQty) => {
    const filteredInventory = inventory.products.filter((item) => item._id !== product._id);
    const {data} = await axios.post("/api/v1/inventory/set-decide-buy-quantity", null, {params: {sellerSku: sku, decidedBuyQuantity: decidedBuyQty === "" ? 0 : decidedBuyQty}});
    setInventory({products: [...filteredInventory, {...product, ...data}]});
    setProduct((old) => ({...old, ...data}));
    setToastMsg("You have successfully set buy quantity.");
    setOpenToast(true);
  };
  return /* @__PURE__ */ React.createElement(Accordion, {
    expanded: expanded === "panel1",
    onChange: handleChange("panel1"),
    style: {borderBottom: "1px solid var(--replen-light-bg)"}
  }, /* @__PURE__ */ React.createElement(AccordionSummary, {
    expandIcon: /* @__PURE__ */ React.createElement(ArrowDropDown, null),
    "aria-controls": "panel-content",
    id: "panel-header"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-summary"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "qty"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, afnTotalQuantity ?? 0)), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", {
    className: "label",
    style: {textAlign: "center"}
  }, "local inv"), /* @__PURE__ */ React.createElement("p", {
    className: "value",
    style: {textAlign: "center"}
  }, localInventory ?? 0)), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", {
    className: "label",
    style: {textAlign: "right"}
  }, "buy qty"), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleChangeBuyQty,
    className: "right"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "number",
    value: buyQty,
    onChange: (e) => setBuyQty(e.target.value),
    className: "value input",
    onClick: (e) => e.target.select(),
    onBlur: handleChangeBuyQty
  }))))), /* @__PURE__ */ React.createElement(AccordionDetails, {
    id: "panel-body"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body"
  }, expandedRows.map((row, index) => /* @__PURE__ */ React.createElement("div", {
    className: "accordion-body-row",
    key: index
  }, /* @__PURE__ */ React.createElement("p", {
    className: row.label === "total" ? "label highlight" : "label",
    style: {paddingLeft: row.sub && "0.5rem"}
  }, row.label, ":"), /* @__PURE__ */ React.createElement("p", {
    className: row.label === "total" ? "value highlight" : "value"
  }, row.value))))));
};
export default Quantities;
