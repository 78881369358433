import React, {useState} from "../../../_snowpack/pkg/react.js";
import {Switch, Route} from "../../../_snowpack/pkg/react-router-dom.js";
import ShoppingList from "../shopping-list/index.js";
import ShoppedList from "../shopped-list/index.js";
import Settings from "../settings/index.js";
import Dashboard from "../dashboard/index.js";
import Inventory from "../inventory/index.js";
import InventoryItem from "../inventory/inventory-item/index.js";
import UnresolvedProducts from "../unresolved-products/index.js";
import Packing from "../packing/index.js";
import BuyListGroup from "../buy-list-group/index.js";
import "./content.css";
const Content = ({isLongPress, setIsLongPress, setDeleteFunction, selectedDelete, setSelectedDelete, setIsRefetchCheckUserEnabled, handleLogout, setNewCartItems, item, setItem, openModal, setOpenModal, user, skip, setSkip, inventory, setInventory}) => {
  return /* @__PURE__ */ React.createElement("main", {
    className: "content"
  }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/shopping-list",
    render: (props) => /* @__PURE__ */ React.createElement(ShoppingList, {
      ...props,
      isLongPress,
      setIsLongPress,
      setDeleteFunction,
      selectedDelete,
      setSelectedDelete,
      setNewCartItems,
      user
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/shopped-list",
    render: (props) => /* @__PURE__ */ React.createElement(ShoppedList, {
      ...props,
      isLongPress,
      setIsLongPress,
      setDeleteFunction,
      selectedDelete,
      setSelectedDelete,
      setNewCartItems,
      user
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/settings",
    render: (props) => /* @__PURE__ */ React.createElement(Settings, {
      ...props,
      setIsRefetchCheckUserEnabled,
      handleLogout
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/dashboard",
    component: Dashboard
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/inventory",
    render: (props) => /* @__PURE__ */ React.createElement(Inventory, {
      ...props,
      skip,
      setSkip,
      inventory,
      setInventory
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/inventory/:productId",
    render: (props) => /* @__PURE__ */ React.createElement(InventoryItem, {
      ...props,
      inventory,
      setInventory
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/unresolved-products",
    component: UnresolvedProducts
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/unresolved-products/pack/:childId",
    render: (props) => /* @__PURE__ */ React.createElement(Packing, {
      ...props,
      item,
      setItem,
      openModal,
      setOpenModal
    })
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/buy-list-group",
    render: (props) => /* @__PURE__ */ React.createElement(BuyListGroup, {
      ...props,
      user
    })
  })));
};
export default Content;
