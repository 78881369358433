import React, {useEffect} from "../../../../../_snowpack/pkg/react.js";
import "./child-row.css";
const ChildRow = ({child, packQty}) => {
  const {title, imageUrl, asin, localInventory} = child.child;
  const {quantity} = child;
  const thumbnail = imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "child-row"
  }, /* @__PURE__ */ React.createElement("img", {
    src: thumbnail,
    alt: `${title} - child item`
  }), /* @__PURE__ */ React.createElement("div", {
    className: "child-info"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "title"
  }, title), /* @__PURE__ */ React.createElement("div", {
    className: "asin-container"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "asin"
  }, asin)))), /* @__PURE__ */ React.createElement("div", {
    className: "child-quantity-info"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "prop-group"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Current Inventory:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, localInventory)), /* @__PURE__ */ React.createElement("div", {
    className: "prop-group"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Qty to be allocated:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, packQty * quantity)), /* @__PURE__ */ React.createElement("div", {
    className: "prop-group"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Qty per parent:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, quantity)), /* @__PURE__ */ React.createElement("div", {
    className: "prop-group"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "label"
  }, "Qty after packing:"), /* @__PURE__ */ React.createElement("p", {
    className: "value"
  }, localInventory - packQty * quantity))));
};
export default ChildRow;
