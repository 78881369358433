import React from "../../../_snowpack/pkg/react.js";
import ReactDOM from "../../../_snowpack/pkg/react-dom.js";
import Snackbar from "../../../_snowpack/pkg/@material-ui/core/Snackbar.js";
import {Alert} from "../../../_snowpack/pkg/@material-ui/lab.js";
import "./toast.css";
const Toast = ({children, isOpen, anchorOrigin, handleClose, type = "success", duration = 6e3}) => {
  return ReactDOM.createPortal(/* @__PURE__ */ React.createElement(Snackbar, {
    className: "toast",
    open: isOpen,
    autoHideDuration: duration,
    onClose: handleClose,
    anchorOrigin
  }, /* @__PURE__ */ React.createElement(Alert, {
    onClose: handleClose,
    severity: type,
    variant: "filled"
  }, children)), document.body);
};
export default Toast;
