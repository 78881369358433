import React, {useEffect, useRef} from "../../../_snowpack/pkg/react.js";
import "./replen-logo-svg.css";
function ReplenLogoSVG({textOnly}) {
  const logoRef = useRef(null);
  return /* @__PURE__ */ React.createElement("svg", {
    ref: logoRef,
    className: "replen-logo-svg",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 174.77 103.28"
  }, /* @__PURE__ */ React.createElement("title", null, "Replen Dashboard Logo"), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    className: "fill-1",
    d: "M5.87,55.91V52.14H0v32.4H5.87V68.33c.61-11.68,10-11.59,10-11.59v-5.8S8.63,50.47,5.87,55.91ZM34.31,80a11.22,11.22,0,1,1,7.43-19.59l-16.1,8.94,2.83,5.14,18.6-10.33,3.06-1.72.07,0c-.1-.17-.2-.32-.29-.46A17.07,17.07,0,1,0,51.3,70H45.41a11.18,11.18,0,0,1-11.1,10ZM124,80a11.22,11.22,0,1,1,7.43-19.59l-16.11,8.94,2.83,5.14,18.6-10.33,3.06-1.72.07,0c-.1-.17-.19-.32-.29-.46A17.07,17.07,0,1,0,141,70H135.1A11.18,11.18,0,0,1,124,80ZM72.68,51.43A17.08,17.08,0,0,0,55.62,68.52v30.2h5.87V68.55a11.18,11.18,0,1,1,2.66,7.25v7.54a17.08,17.08,0,1,0,8.53-31.91Zm28.6-13.74H95.41V85h5.87Zm59.53,13.08a14,14,0,0,0-13.95,14V85h5.86V64.75a8.09,8.09,0,1,1,16.18,0V85h5.87V64.75A14,14,0,0,0,160.81,50.77ZM89.5,4.75A33.84,33.84,0,0,1,122,29.14a32.67,32.67,0,0,0-65,0A33.87,33.87,0,0,1,89.5,4.75Z"
  }), /* @__PURE__ */ React.createElement("path", {
    className: "fill-1",
    d: "M100.25,13.69c.17-.25.3-.95,0-1.18s-.93.13-1.12.37L88.94,25.64a2.48,2.48,0,0,0-.39,0,2.89,2.89,0,1,0,2.88,2.89,3.12,3.12,0,0,0-.25-1.18l9.07-13.64Z"
  }), /* @__PURE__ */ React.createElement("path", {
    className: "fill-2",
    d: "M34.17,3.92A19.16,19.16,0,0,0,15.13,21a19.84,19.84,0,0,1,38.08,0,19.15,19.15,0,0,0-19-17.09Z"
  }), /* @__PURE__ */ React.createElement("path", {
    className: "fill-2",
    d: "M34.58,20.4a.38.38,0,0,0-.19,0l-6.38-8c-.1-.12-.49-.34-.66-.19s-.1.54,0,.68L33,21.48a1.68,1.68,0,1,0,1.56-1.08ZM143.75,6.71a19.86,19.86,0,0,1,19,14.28,19.14,19.14,0,0,0-38.07,0A19.85,19.85,0,0,1,143.75,6.71Z"
  }), /* @__PURE__ */ React.createElement("path", {
    className: "fill-2",
    d: "M155.12,18.74l-10.29,2.13a1.61,1.61,0,0,0-1.17-.47,1.69,1.69,0,1,0,1.63,2.13l10-3c.17-.05.49-.32.44-.54s-.46-.27-.63-.24ZM143.78,94.6a4.34,4.34,0,1,0,4.32,4.34A4.33,4.33,0,0,0,143.78,94.6Zm0,7.18a2.87,2.87,0,1,1,2.86-2.87A2.86,2.86,0,0,1,143.78,101.78ZM97.66,98.92a2.86,2.86,0,1,1-2.86-2.87,2.8,2.8,0,0,1,1.88.71V95a4.4,4.4,0,0,0-1.88-.44,4.34,4.34,0,1,0,4.33,4.33V90H97.66v8.89Zm74.91-8.87v8.87a2.86,2.86,0,1,1-2.86-2.87,2.8,2.8,0,0,1,1.88.71V95a4.4,4.4,0,0,0-1.88-.44A4.34,4.34,0,1,0,174,98.89V90h-1.46v0ZM133.49,94.6a4.31,4.31,0,0,0-1.88.45v1.76A2.86,2.86,0,0,1,136.35,99a2.86,2.86,0,1,1-5.72,0V90.1h-1.47v8.82a4.33,4.33,0,1,0,4.33-4.32Zm20.85,0a4.34,4.34,0,1,0,1.88,8.23v-1.76a2.9,2.9,0,0,1-1.88.71,2.87,2.87,0,1,1,2.85-2.86v4.31h1.47V98.92a4.34,4.34,0,0,0-4.32-4.31Zm-49.25,0a4.34,4.34,0,1,0,1.88,8.23v-1.76a2.86,2.86,0,0,1-4.74-2.15,2.86,2.86,0,1,1,5.72,0v4.31h1.47V98.92a4.35,4.35,0,0,0-4.33-4.31Zm56.63.62v-.93h-1.45v9h1.45v-5c.14-2.89,2.47-2.86,2.47-2.86V94a2.77,2.77,0,0,0-2.47,1.23ZM123.44,93.9a3.82,3.82,0,0,0-2.24.75v-5.9h-1.47v14.5h1.47V97.62h0A2.21,2.21,0,0,1,125,96.05a2.2,2.2,0,0,1,.64,1.57h0v5.63h1.47V97.62A3.7,3.7,0,0,0,126,95a3.49,3.49,0,0,0-2.59-1.11Zm-5.59,5.8a3.53,3.53,0,0,0-.64-.54,4.49,4.49,0,0,0-.76-.37h0l-3.08-1.17-.54-.27a1,1,0,0,1-.22-.15.45.45,0,0,1-.12-.15.75.75,0,0,1,0-.31.86.86,0,0,1,.22-.59.65.65,0,0,1,.2-.15.79.79,0,0,1,.22,0h4.45V94.48h-4.45a2.06,2.06,0,0,0-1.76,1,2.41,2.41,0,0,0-.34,1.25,2,2,0,0,0,.12.78,1.75,1.75,0,0,0,.29.52,3.53,3.53,0,0,0,.64.54,5.83,5.83,0,0,0,.75.37h0l3.08,1.17c.22.1.39.2.54.27a1,1,0,0,1,.22.15.64.64,0,0,1,.12.14.94.94,0,0,1-.17.91.8.8,0,0,1-.2.15.87.87,0,0,1-.22,0h-4.69v1.47h4.69a2.16,2.16,0,0,0,.86-.2,2.2,2.2,0,0,0,.9-.83,2.41,2.41,0,0,0,.34-1.25,2.11,2.11,0,0,0-.12-.79,1.83,1.83,0,0,0-.29-.51Z"
  })));
}
export default ReplenLogoSVG;
