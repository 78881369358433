import passwordValidator from '../../../_snowpack/pkg/password-validator.js'

const schema = new passwordValidator()

schema.is().min(8)
             .has().uppercase()
             .has().lowercase()
             .has().digits()
             .has().symbols()

const checkPassword = params => {
    return schema.validate(params)
}

export default checkPassword