import React from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
import {ChevronRight} from "../../../_snowpack/pkg/@material-ui/icons.js";
import numberToUSD from "../../utilities/js/number-to-usd.js";
import "./shopping-list-item.css";
const ShoppingListItem = ({product, setSelected, linkTo}) => {
  const {
    imageUrl,
    afnTotalQuantity,
    decidedBuyQuantity,
    asin,
    supplier,
    costPrice,
    title,
    sellerSku,
    storeSection,
    isAlwaysBuyList,
    customTitle,
    isSellable
  } = product;
  const thumbnail = imageUrl ? `https://images-na.ssl-images-amazon.com/images/I/${imageUrl.split(".")[0]}._AC_US96_.jpg` : "/assets/images/no-image-available.svg";
  const handleClick = () => setSelected(product);
  const isZeroBuyQty = decidedBuyQuantity === 0 || decidedBuyQuantity === null || decidedBuyQuantity === void 0;
  const shoppingListItemClass = `shopping-list-item${isZeroBuyQty && !isAlwaysBuyList ? " zero-buy-qty" : ""}`;
  const filteredSellerSku = sellerSku?.includes("#") ? sellerSku.replace("#", "") : sellerSku;
  return /* @__PURE__ */ React.createElement(Link, {
    to: `/${linkTo}/${encodeURIComponent(filteredSellerSku)}`,
    onClick: handleClick,
    className: shoppingListItemClass
  }, /* @__PURE__ */ React.createElement("img", {
    className: "thumbnail",
    src: thumbnail
  }), /* @__PURE__ */ React.createElement("div", {
    className: "title"
  }, customTitle ?? title, " ", !isSellable == void 0 || isSellable == false ? "(Non Sellable)" : ""), /* @__PURE__ */ React.createElement("div", {
    className: "props"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "SKU"), /* @__PURE__ */ React.createElement("div", null, sellerSku)), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Cost"), /* @__PURE__ */ React.createElement("div", null, costPrice ? numberToUSD(costPrice ?? 0) : "N/A")), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "QTY"), /* @__PURE__ */ React.createElement("div", null, afnTotalQuantity)), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Supplier"), /* @__PURE__ */ React.createElement("div", null, supplier ?? "N/A")), /* @__PURE__ */ React.createElement("div", {
    className: "prop"
  }, /* @__PURE__ */ React.createElement("label", null, "Store Section"), /* @__PURE__ */ React.createElement("div", null, storeSection ?? "N/A"))), /* @__PURE__ */ React.createElement("div", {
    className: "buy-qty"
  }, /* @__PURE__ */ React.createElement("span", null, isAlwaysBuyList ? "∞" : decidedBuyQuantity ? decidedBuyQuantity : 0), /* @__PURE__ */ React.createElement(ChevronRight, null)));
};
export default ShoppingListItem;
